import axios from 'axios'
import { defineStore } from 'pinia';
import { ref } from 'vue';

const instance = axios.create({
    baseURL: 'https://api.airtable.com/v0/app5N6iplatN9swsc/',
    headers: {
        Authorization: 'Bearer keyVJRh82TPHU88xc'
    },
})

export const useNeigeStore = defineStore('neige-store', () => {
    const arrayNeige = ref([])
    const altitude = ref([])
    const pending = ref(true);
    
    function getRegionsNeige(){
        return new Promise((resolve, reject) => {
            instance.get('tblMPLWtk2zmk11NW?sort%5B0%5D%5Bfield%5D=departement')
            .then((proxy) => {
                arrayNeige.value = [...proxy.data.records]
                resolve(arrayNeige)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    function getAltitude(){
        return new Promise((resolve, reject) => {
            instance.get('tblhAsgNHPQmRLxvF?view=Grid%20view')
            .then((proxy) => {
                altitude.value = []
                let arr = [...proxy.data.records]
                for (let index = 0; index < arr.length; index++) {
                    let element = arr[index].fields;     
                    
                    let ref = element.altitude_a.split('-')
                    element.altitude_a1 = ref[0];
                    element.altitude_a2 = ref[1];
                    altitude.value.push(element)
                }
                pending.value = false
                resolve(altitude)
            })
            .catch((err) => {
                reject(err)
            })
        })
        
    }
    return {
        arrayNeige, 
        altitude,
        pending,
        getRegionsNeige,
        getAltitude,
    }
})