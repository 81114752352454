import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/projet/',
      name: 'projet',
      component: () => import('../views/ProjetView.vue')
    },
    {
      path: '/pdf/',
      name: 'pdf',
      component: () => import('../views/PDFView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    }
  ]
})

// router.beforeEach((to, from, next) => {
//   console.log(to)
//   next()
// })

export default router
