import axios from 'axios'
import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

const instance = axios.create({
    baseURL: 'https://api.airtable.com/v0/app5N6iplatN9swsc/',
    headers: {
        Authorization: 'Bearer keyVJRh82TPHU88xc'
    },
})

export const useTextesStore = defineStore('textes-store', () => {
    const textes = ref([]);
    function getTextes(){
        return new Promise((resolve, reject) => {
            instance.get('tblGtVikuahE4YjWp?view=Grid%20view')
            .then((proxy) => {
                textes.value = [...proxy.data.records]
                // console.log(textes.value)
                resolve(textes)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    return {
        textes, 
        getTextes
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTextesStore, import.meta.hot));
}
