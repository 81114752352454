import axios from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const instance = axios.create({
    baseURL: 'https://api.airtable.com/v0/app5N6iplatN9swsc/',
    headers: {
        Authorization: 'Bearer keyVJRh82TPHU88xc'
    },
})

export const useVentStore = defineStore('vent-store', () => {
    const arrayVent = ref([]), 
    categorie_terrain = ref([]),
    cpe90 = ref([]),
    cpe0 = ref([]);

    
    function getRegionsVent(){
        return new Promise((resolve, reject) => {
            instance.get('tblCIkYm0WiSisAT4')
            .then((proxy) => {
                arrayVent.value = proxy.data.records
                resolve(arrayVent)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    function getCategorieTerrain(){
        return new Promise((resolve, reject) => {
            instance.get('tblSgaLiUwmDjPu1X?sort%5B0%5D%5Bfield%5D=label')
            .then((proxy) => {
                categorie_terrain.value = proxy.data.records
                resolve(categorie_terrain)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    function getCpePentes90(){
        return new Promise((resolve, reject) => {
            cpe90.value = []
            instance.get('tblOV1KyliykMTdZR?view=Grid%20view')
            .then((proxy) => {
                let arr = proxy.data.records
                for (let index = 0; index < arr.length; index++) {
                    const element = arr[index].fields;
                    cpe90.value.push(element)
                }
                resolve(cpe90)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    function getCpePentes0(){
        return new Promise((resolve, reject) => {
            cpe0.value = []
            instance.get('tblhTboNb5dU8uxkX?view=Grid%20view')
            .then((proxy) => {
                let arr = proxy.data.records
                for (let index = 0; index < arr.length; index++) {
                    const element = arr[index].fields;
                    cpe0.value.push(element)
                }
                resolve(cpe0)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    return {
        arrayVent,
        categorie_terrain,
        cpe90,
        cpe0,
        getRegionsVent,
        getCategorieTerrain,
        getCpePentes90,
        getCpePentes0
    }
})