import { createPinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
import localforage, { getItem } from 'localforage'

export const plugin = (app) => {
    const pinia = createPinia()

    pinia.use(
        createPersistedStatePlugin({
            storage: {
                getItem: async (key) => {
                    return localforage.getItem(key)
                },
                setItem: async (key, value) => {
                    return localforage.setItem(key, value)
                },
                removeItem: async (key) => {
                    return localforage.removeItem(key)
                },
            },
        }),
    )
    app.use(pinia)
}