import axios from 'axios'
import { createPinia, defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue';
import { useNeigeStore } from '@/stores/neige'
import { useVentStore } from '@/stores/vent'

const instance = axios.create({
    baseURL: 'https://api.airtable.com/v0/app5N6iplatN9swsc/',
    headers: {
        Authorization: 'Bearer keyVJRh82TPHU88xc',
        "Content-Type": "application/json",
    },
})

export const useFormStore = defineStore('form-store', () => { 
    const formStep = ref(1); 
    const formStep_valid = ref(0);
    const kmod = ref([]);
    const info = ref({})

    const messageShow = ref(false);
    const messageToast = ref('');

    const loading = ref(false);

    const initial = ref([]);
    const best = ref({});

    const init_hauteur_total_chevetre = ref(0);
    const init_largeur_total_chevetre = ref(0);
    const init_largeur_section_chevetre = ref(0);

    const storeNeige = useNeigeStore();
    const storeVent = useVentStore();
    
    const Sn_neige = ref(0)
    const Sny = ref(0)
    const Snz = ref(0)
    
    const QpZ = ref(0);
    const FwN_result = ref(0);
    const Fw_result = ref(0)
    const longueur_traverse = ref(0)
    const longueur_montant = ref(0)
    const longueur_montant2 = ref(0)
    const module_flexion = ref(0)

    const masse_volumique = ref(420)
    const PPtotVertical = ref(0)
    const PPtotY = ref(0)
    const PPtotZ = ref(0)

    const d1_trav = ref(0)
    const d2_trav = ref(0)
    const L_trav = ref(0)

    const a1_trav = ref(0)
    const a2_trav = ref(0) 
    const a3_trav = ref(0)

    const d1_mont = ref(0)
    const d2_mont = ref(0)
    const L_mont = ref(0)

    const a1_mont = ref(0)
    const a2_mont = ref(0) 
    const a3_mont = ref(0)

    const d1_mont2 = ref(0)
    const d2_mont2 = ref(0)
    const L_mont2 = ref(0)

    const a1_mont2 = ref(0)
    const a2_mont2 = ref(0) 
    const a3_mont2 = ref(0)

    const fleche_permanente_y = ref(0)
    const fleche_neige_y = ref(0)
    const fleche_pression_y = ref(0)

    const mydmax_permanente_y = ref(0)
    const mydmax_neige_y = ref(0)
    const mydmax_pression_y = ref(0)

    const tmax_permanente_y = ref(0)
    const tmax_neige_y = ref(0)
    const tmax_pression_y = ref(0)

    const fleche_permanente_z = ref(0)
    const fleche_neige_z = ref(0)
    const fleche_pression_z = ref(0)

    const mydmax_permanente_z = ref(0)
    const mydmax_neige_z = ref(0)
    const mydmax_pression_z = ref(0)

    const tmax_permanente_z = ref(0)
    const tmax_neige_z = ref(0)
    const tmax_pression_z = ref(0)

    const fleche_permanente_y_mont = ref(0)
    const fleche_neige_y_mont = ref(0)
    const fleche_pression_y_mont = ref(0)

    const mydmax_permanente_y_mont = ref(0)
    const mydmax_neige_y_mont = ref(0)
    const mydmax_pression_y_mont = ref(0)

    const tmax_permanente_y_mont = ref(0)
    const tmax_neige_y_mont = ref(0)
    const tmax_pression_y_mont = ref(0)

    const fleche_permanente_y_mont2 = ref(0)
    const fleche_neige_y_mont2 = ref(0)
    const fleche_pression_y_mont2 = ref(0)

    const mydmax_permanente_y_mont2 = ref(0)
    const mydmax_neige_y_mont2 = ref(0)
    const mydmax_pression_y_mont2 = ref(0)

    const tmax_permanente_y_mont2 = ref(0)
    const tmax_neige_y_mont2 = ref(0)
    const tmax_pression_y_mont2 = ref(0)

    const n_defavorable = ref(0)
    const traverse_deformation = ref(0)
    const montant_flexion = ref(0)
    const montant_cisaillement = ref(0)
    const montant_deformation = ref(0)
    const chevron_ext_flexion = ref(0)
    const chevron_ext_flexion_cisaillement = ref(0)
    const chevron_ext_deformation = ref(0)

    const init = ref(false)
    const arr_optimisation = ref([])

    const currentLargeur = ref(0)
    const largeur_opti = ref(0)

    const last_refID = ref(0);

    const determinant = m => 
    m.length == 1 ?
    m[0][0] :
    m.length == 2 ? 
    m[0][0]*m[1][1]-m[0][1]*m[1][0] :
    m[0].reduce((r,e,i) => 
        r+(-1)**(i+2)*e*determinant(m.slice(1).map(c => 
        c.filter((_,j) => i != j))),0)

    function getKMOD(){
        return new Promise((resolve, reject) => {
            kmod.value = []
            instance.get('tblZErK3DEnjDbvaa?view=Grid%20view')
            .then(proxy => {
                if(proxy.data.records.length > 0){
                    for (let index = 0; index < proxy.data.records.length; index++) {
                        const element = proxy.data.records[index];
                        kmod.value.push(element.fields)
                    }
                }

                resolve(proxy)
            })
            .catch(err => {
                reject(err)
            })
        })
    }

    function setInput(payload){
        return new Promise((resolve, reject) => {
            info.value = payload
            let data = {
                "records":[
                    {
                        "fields": {
                            'nom_projet': payload.nom_projet,
                            'remarque': payload.remarque,
                            'departement': [payload.departement[0]],
                            'altitude': payload.altitude,
                            'categorie_terrain': [payload.categorie_terrain[0]],
                            'pente_toiture': payload.pente_toiture,
                            'hauteur_toiture': payload.hauteur_toiture,
                            'largeur_toiture': payload.largeur_toiture,
                            'longueur_toiture': payload.longueur_toiture,
                            'type_charpente': payload.type_charpente,
                            'largeur_chevron': payload.largeur_chevron,
                            'hauteur_chevron': payload.hauteur_chevron,
                            'entraxe_chevron': payload.entraxe_chevron,
                            'entraxe_panne': payload.entraxe_panne,
                            'type_couverture': [payload.type_couverture[0]],
                            'isolation': [payload.isolation[0]],
                            'support_couverture': [payload.support_couverture[0]],
                            'second_oeuvre': [payload.second_oeuvre[0]],
                            'divers_1': [payload.divers_1[0]],
                            'largeur_section_chevetre': payload.largeur_section_chevetre,
                            'hauteur_section_chevetre': payload.hauteur_section_chevetre,
                            'disposition': [payload.disposition[0].id],
                            'reference': [payload.reference[0].id],
                            'largeur_velux': payload.largeur_velux,
                            'hauteur_velux': payload.hauteur_velux,
                            'masse_velux': payload.masse_velux,
                            'nombre_velux': payload.nombre_velux,
                            'h1': payload.h1,
                            'h2': payload.h2,
                            'bande_charge_velux': payload.bande_charge_velux,
                            'classe_service': payload.classe_service,
                            'jeu_velux': payload.jeu_velux,
                            'largeur_total_chevetre': payload.largeur_total_chevetre,
                            'hauteur_total_chevetre': payload.hauteur_total_chevetre,
                            'masse_total_kg': payload.masse_total_kg,
                            'masse_total_n': payload.masse_total_n,
                            'entreprise': payload.entreprise,
                            'nom': payload.nom,
                            'email': payload.email,
                            'departement_client': payload.departement_client,
                        }
                    },
                ]
            }
            console.log(data)

            resolve(data);
        })
    }

    function sendInput(){
        return new Promise((resolve, reject) => {
            let payload = info.value
            let data = {
                "records":[
                    {
                        "fields": {
                            'nom_projet': payload.nom_projet,
                            'remarque': payload.remarque,
                            'departement': [payload.departement[0]],
                            'altitude': payload.altitude,
                            'categorie_terrain': [payload.categorie_terrain[0]],
                            'pente_toiture': payload.pente_toiture,
                            'hauteur_toiture': payload.hauteur_toiture,
                            'largeur_toiture': payload.largeur_toiture,
                            'longueur_toiture': payload.longueur_toiture,
                            'type_charpente': payload.type_charpente,
                            'largeur_chevron': payload.largeur_chevron,
                            'hauteur_chevron': payload.hauteur_chevron,
                            'entraxe_chevron': payload.entraxe_chevron,
                            'entraxe_panne': payload.entraxe_panne,
                            'type_couverture': [payload.type_couverture[0]],
                            'isolation': [payload.isolation[0]],
                            'support_couverture': [payload.support_couverture[0]],
                            'second_oeuvre': [payload.second_oeuvre[0]],
                            'divers_1': [payload.divers_1[0]],
                            'largeur_section_chevetre': payload.largeur_section_chevetre,
                            'hauteur_section_chevetre': payload.hauteur_section_chevetre,
                            'disposition': [payload.disposition[0].id],
                            'reference': [payload.reference[0].id],
                            'largeur_velux': payload.largeur_velux,
                            'hauteur_velux': payload.hauteur_velux,
                            'masse_velux': payload.masse_velux,
                            'nombre_velux': payload.nombre_velux,
                            'h1': payload.h1,
                            'h2': payload.h2,
                            'bande_charge_velux': payload.bande_charge_velux,
                            'classe_service': payload.classe_service,
                            'jeu_velux': payload.jeu_velux,
                            'largeur_total_chevetre': payload.largeur_total_chevetre,
                            'hauteur_total_chevetre': payload.hauteur_total_chevetre,
                            'masse_total_kg': payload.masse_total_kg,
                            'masse_total_n': payload.masse_total_n,
                            'entreprise': payload.entreprise,
                            'nom': payload.nom,
                            'email': payload.email,
                            'departement_client': payload.departement_client,
                        }
                    },
                ]
            }
            instance.post('tblwoKxvaYEZL00ga', data)
            .then(proxy => {
                last_refID.value = proxy.data.records[0].id
                resolve(proxy)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }

    function uploadDoc(doc, name){
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("file", doc);
            data.append('name', name);
            axios({
                method: "POST",
                url: `https://dev-serveur.fr/velux/pdfs.php`,
                data: data,
            })
            .then((res) => {
                if(last_refID !== 0){
                    let data = {
                        "records":[
                            {
                                "id": last_refID.value,
                                "fields": {
                                    "resultats": `https://dev-serveur.fr/velux/${res.data}`,
                                    "fichier": [
                                        {
                                            "url": `https://dev-serveur.fr/velux/${res.data}`
                                        }
                                    ],
                                }
                            },
                        ]
                    }
                    instance.patch('tblwoKxvaYEZL00ga', data)
                    .then(proxy => {
                        console.log(proxy)
                        resolve(proxy)
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error.message);
            });
        });
    }

    function createCalcul(){
        return new Promise((resolve, reject) => {
            resetCalculValeurs().then(() => {
                info.value.largeur_chevron = info.value.largeur_chevron * 10
                info.value.hauteur_chevron = info.value.hauteur_chevron * 10
                info.value.entraxe_chevron = info.value.entraxe_chevron * 10
                info.value.entraxe_panne = info.value.entraxe_panne * 10
                info.value.largeur_section_chevetre = info.value.largeur_section_chevetre * 10
                info.value.hauteur_section_chevetre = info.value.hauteur_section_chevetre * 10
                
                calculNeige();
                calculVent();
                calculVentPlongPan();
                calculVentPPigon();
                calculChargePP();
                calculTravRDM_Y();
                calculTravRDM_Z();
                calculTrav_ComELU();
                calculTrav_CombELS();
                calculMont1_RDM_Y();
                calculMont1_ComELU();
                calculMont1_CombELS();
                calculMont2_RDM_Y();
                calculMont2_ComELU();
                calculMont2_CombELS();
                if(!init.value){
                    init_hauteur_total_chevetre.value = info.value.hauteur_total_chevetre;
                    init_largeur_total_chevetre.value = info.value.largeur_total_chevetre;
                    calculInitOptimisation()
                    .then((res) => {
                        console.log('hello')
                        sendInput()
                        resolve(currentLargeur.value)
                    })
                } else {
                    calculOptimisation()
                    .then((res) => {
                        resolve(currentLargeur.value)
                    })
                }
            })
        })
    }

    function calculNeige(){
        console.log('====== CALCUL NEIGE ======')

        let toFound = info.value.departement[0]
        const found = storeNeige.arrayNeige.find(region => region.id === toFound)
        let currentDepartement = found.fields
        console.log('Valeur dept. : ', currentDepartement)
        console.log('Altitude: ', info.value.altitude)

        let altitude_a
        
        for (let index = 0; index < storeNeige.altitude.length; index++) {
            const element = storeNeige.altitude[index];
            if(info.value.altitude >= element.altitude_a1 && info.value.altitude < element.altitude_a2){
                altitude_a = element;
            }
        }
        console.log("Altitude A: ", altitude_a)

        let pente = info.value.pente_toiture;
        console.log('Pente: ', pente)

        let deltaS1 = altitude_a.s1
        let deltaS2 = altitude_a.s2

        let sK
        if(pente <= 3){
            let result     
            if(currentDepartement.code[0] === 'E'){
                result = currentDepartement.neige_sk200[0] + deltaS2
            } else {
                result = currentDepartement.neige_sk200[0] + deltaS1
            }
            sK = result + 0.2
        } else {
            let result 
            if(currentDepartement.code[0] === 'E'){
                result = currentDepartement.neige_sk200[0] + deltaS2
            } else {
                result = currentDepartement.neige_sk200[0] + deltaS1
            }
            sK = result
        }
        console.log("sK: ", sK);

        let u2
        if(pente <= 30){
            u2 = 0.8
        } else {
            if(pente > 30 && pente <= 60){
                u2 = (0.8 * (60 - pente)) / 30
            } else {
                u2 = 0
            }
        }
        console.log('u2: ', u2);

        let Ce = 1.00;
        let Ct = 1.00;

        let Sn = (u2 * Ce * Ct * sK) * Math.pow(10, -3);
        console.log('Sn: ', Sn);

        Sn_neige.value = Sn

        let Sx = (u2 * Ce * Ct * currentDepartement.neige_sAd[0]) * Math.pow(10, -3);
        console.log('Sx: ', Sx);

        Sny.value = (Sn * Math.cos(pente * (Math.PI/180)));
        console.log('Sny: ', Sny.value);

        Snz.value = (Sn * Math.cos((90 - pente) * (Math.PI/180)))
        console.log('Snz: ', Snz.value);

        console.log('==========================');
    }

    function calculVent(){
        console.log('====== CALCUL VENT Qp (z) =======')
        
        let toFound = info.value.departement[1]
        const found = storeVent.arrayVent.find(region => region.fields.departement === toFound)
        let currentDepartement = found.fields
        console.log('Valeur dept. : ', currentDepartement)

        let zone = currentDepartement.code[0]
        console.log('Zone: ', zone)

        let Vb0
        if(zone == 1){
            Vb0 = 22
        } else if (zone == 2){
            Vb0 = 24
        } else if(zone == 3){
            Vb0 = 26
        } else if(zone == 4){
            Vb0 = 28
        }
        console.log('Vb0: ', Vb0)

        let catTerrain = info.value.categorie_terrain
        console.log('Cat. terrain: ', catTerrain[1])

        let z0 = catTerrain[2];
        console.log('Z0: ', z0);

        let zmin = catTerrain[3];
        console.log('Zmin: ', zmin);

        let Vb = 1 * 1 * Vb0;
        console.log('Vb: ', Vb);

        let C0z = 1;
        console.log('C0(Z): ', C0z);

        let Kr = 0.19 * Math.pow((z0/0.05), 0.07)
        console.log('Kr: ', Math.round(Kr) * 1000 / 1000)

        let hauteurToiture = info.value.hauteur_toiture
        let crZ = Kr * Math.log(Math.min(200, Math.max(zmin, hauteurToiture))/ z0)
        console.log('cr(Z): ', Math.round(crZ * 1000) / 1000)

        let Vm = crZ * C0z * Vb
        console.log('Vm', Math.round(Vm * 1000) / 1000)

        let Ki = C0z * (1 - 2 * Math.pow(10, -4) * Math.pow((Math.log10(z0) + 3), 6))
        console.log('KI: ', Math.round(Ki * 1000) / 1000)

        let Ivx = Ki / (C0z * Math.log(Math.min(200, Math.max(zmin, hauteurToiture))/z0))
        console.log('Iv(x): ', Math.round(Ivx * 1000) / 100);

        let Qpz = (1+7*Ivx)*1/2*1.225*Math.pow(Vm, 2)
        console.log('Qp(z): ', Math.round(Qpz))

        QpZ.value = Qpz
        console.log('==========================');
    }

    function calculVentPlongPan(){
        console.log('====== CALCUL VENT PERPENDICULAIRE AU LONG PAN ======')

        let pente = info.value.pente_toiture;
        let b = info.value.longueur_toiture
        let d = info.value.largeur_toiture
        let e = Math.min(2 * info.value.hauteur_toiture, b)

        console.log('b: ', b, 'd: ', d, 'e: ', e)

        let F = (e / 4) * (e/10) * (1/Math.cos(pente * (Math.PI/180)));
        let G = (b-e/2) * e/10 * 1/Math.cos(pente * (Math.PI / 180));
        let H = ((d/2)-(e/10)) * (b / Math.cos(pente * (Math.PI/180)));
        let I = ((d/2)-(e/10)) * (b / Math.cos(pente * (Math.PI/180)));
        let J = (e/10) * (b / Math.cos(pente * (Math.PI/180)));
        console.log('F: ', Math.round(F * 100) / 100, 'G: ', Math.round(G * 100) / 100, 'H: ', Math.round(H * 100) / 100, 'I: ', Math.round(I * 100) / 100, 'J: ', Math.round(J * 100) / 100)

        let toFound = pente
        const found = storeVent.cpe0.find(degree => degree.pente === toFound)

        let cpe10 = {
            F: found.F,
            G: found.G,
            H: found.H,
            I: found.I,
            J: found.J,
        }
        console.log('cpe10: ',cpe10)

        let CpeMoyen = ((F * cpe10.F) + (G * cpe10.G) + (H * cpe10.H)) / (F + G + H)
        console.log('Cpe moyen: ', Math.round(CpeMoyen * 100) / 100)

        let CpeCpi = CpeMoyen - 0.2
        console.log('Cpe - Cpi: ', Math.round(CpeCpi * 100) / 100)

        let Fw = CpeMoyen * QpZ.value
        let FwKn = Fw * Math.pow(10, -3)
        console.log('Fw: ',Math.round(Fw * 100) / 100, 'FwKn: ',Math.round(FwKn * 100) / 100)

        let FwVertical = Fw * Math.pow(10, -6) * Math.sin(pente * (Math.PI/180))
        console.log('Fw Vertical: ',FwVertical)

        let FwN = Fw * Math.pow(10, -6);
        console.log('Fw: ',FwN)
        FwN_result.value = FwN

        console.log('=====================================================');
    }

    function calculVentPPigon(){
        console.log('====== CALCUL VENT PERPENDICULAIRE AU PIGON ======')
        
        let pente = info.value.pente_toiture;
        let b = info.value.largeur_toiture
        let d = info.value.longueur_toiture
        let e = Math.min(2 * info.value.hauteur_toiture, b)
        console.log('b: ', b, 'd: ', d, 'e: ', e)

        let F = ((e / 4) * (e/10) * (1/Math.cos(pente * (Math.PI/180))));
        let G = (b/2-e/4) * b/10 * 1/Math.cos(pente * (Math.PI/180));
        let H = b / 2 * (e/2-e/10) * 1/Math.cos(pente * (Math.PI/180));
        let I = (d-e/2)*e/2*1/Math.cos(pente * (Math.PI/180));
        console.log('F: ', Math.round(F * 100) / 100, 'G: ', Math.round(G * 100) / 100, 'H: ', Math.round(H * 100) / 100, 'I: ', Math.round(I * 100) / 100)

        let toFound = pente
        const found = storeVent.cpe90.find(degree => degree.pente === toFound)

        let cpe10 = {
            F: found.F,
            G: found.G,
            H: found.H,
            I: found.I,
        }
        console.log('cpe10: ',cpe10)

        let CpeMoyen = ((F * cpe10.F) + (G * cpe10.G) + (H * cpe10.H) + (I * cpe10.I)) / (F + G + H + I)
        console.log('Cpe moyen: ', Math.round(CpeMoyen * 100) / 100)

        let CpeCpi = CpeMoyen - 0.2
        console.log('Cpe - Cpi: ', Math.round(CpeCpi * 100) / 100)

        let Fw = CpeCpi * QpZ.value
        Fw_result.value = Fw

        console.log('Fw: ', Math.round(Fw * 100) / 100)
        console.log('=====================================================');
    }

    function calculChargePP(){
        console.log('====== CALCUL Charge Total PP =======')

        console.log(info.value)

        let type_dcouverture = info.value.type_couverture[1]
        let isolation = info.value.isolation[1]
        let support_couverture = info.value.support_couverture[1]
        let chevron = ((masse_volumique.value * info.value.largeur_chevron * Math.pow(10, -3) * info.value.hauteur_chevron * Math.pow(10, -3) * 1) / 0.6) * Math.pow(10, -2)
        let second_oeuvre = info.value.second_oeuvre[1]
        let divers_1 = info.value.divers_1[1]

        // console.table({
        //     type_dcouverture: Math.round(type_dcouverture * 10) / 10,
        //     isolation: Math.round(isolation * 100) / 100,
        //     support_couverture: Math.round(support_couverture * 100) / 100,
        //     chevron: Math.round(chevron * 100) / 100,
        //     second_oeuvre: Math.round(second_oeuvre * 10) / 10,
        //     divers_1: Math.round(divers_1 * 100) / 100,
        // })

        let pente = info.value.pente_toiture
        PPtotVertical.value = type_dcouverture + isolation + support_couverture + chevron + second_oeuvre + divers_1
        console.log('PP tot vertical: ', Math.round(PPtotVertical.value * 1000) / 1000)

        PPtotY.value = PPtotVertical.value * Math.cos((pente * (Math.PI/180))) * Math.pow(10, -3)
        console.log('PP tot y: ', Math.round(PPtotY.value * 100000000) / 100000000)
        
        PPtotZ.value = PPtotVertical.value * Math.cos(((90 - pente) * (Math.PI/180))) * Math.pow(10, -3)
        console.log('PP tot z: ', Math.round(PPtotZ.value * 100000000) / 100000000)

        console.log('=====================================');
    }

    function calculTravRDM_Y(){
        console.log('====== CALCUL TravRDM_Y ======')

        longueur_traverse.value = (Math.ceil(info.value.largeur_total_chevetre/info.value.entraxe_chevron) * info.value.entraxe_chevron) - info.value.largeur_chevron
        module_flexion.value = 11000;
        console.log('Longueur traverse: ', longueur_traverse.value, 'Module flexion: ', module_flexion.value)

        d1_trav.value = info.value.entraxe_chevron / 2
        d2_trav.value = longueur_traverse.value - d1_trav.value
        L_trav.value = longueur_traverse.value

        a1_trav.value = d1_trav.value
        a2_trav.value = d2_trav.value - d1_trav.value 
        a3_trav.value = longueur_traverse.value - d2_trav.value
        console.log('d1_trav: ', d1_trav.value, 'd2_trav: ', d2_trav.value, 'L_trav: ', L_trav.value, 'a1_trav: ', a1_trav.value, 'a2_trav: ', a2_trav.value, 'a3_trav: ', a3_trav.value)

        console.log("/// Charge de vent pression ///")

        let q1_pression = FwN_result.value * (info.value.hauteur_total_chevetre / 2)
        let q2_pression = FwN_result.value * (info.value.hauteur_total_chevetre / 2) + (FwN_result.value * info.value.bande_charge_velux)
        let q3_pression = FwN_result.value * (info.value.hauteur_total_chevetre / 2)
        console.log('q1: ', Math.round(q1_pression * 1000) / 1000, 'q2: ', Math.round(q2_pression * 1000) / 1000, 'q3: ', Math.round(q3_pression * 1000) / 1000)

        let result_pression = calculMatriceY_Trav(q1_pression, q2_pression, q3_pression)
        fleche_pression_y.value = result_pression.fleche_max
        mydmax_pression_y.value = result_pression.mydmax
        tmax_pression_y.value = result_pression.tmax

        console.log("/// Charge de neige Sn ///")

        let q1_neige = Sny.value * (info.value.hauteur_total_chevetre / 2);
        let q2_neige = Sny.value * (info.value.hauteur_total_chevetre / 2) + (Sny.value * info.value.bande_charge_velux);
        let q3_neige = Sny.value * (info.value.hauteur_total_chevetre / 2);
        console.log('q1:', Math.round(q1_neige * 1000) / 1000, 'q2: ', Math.round(q2_neige * 1000) / 1000, 'q3: ', Math.round(q3_neige * 1000) / 1000)

        let result_neige = calculMatriceY_Trav(q1_neige, q2_neige, q3_neige)
        fleche_neige_y.value = result_neige.fleche_max
        mydmax_neige_y.value = result_neige.mydmax
        tmax_neige_y.value = result_neige.tmax

        console.log("/// Charge permanente ///")

        let q1_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2)
        let q2_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2) + (PPtotY.value * info.value.bande_charge_velux)
        let q3_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2)
        console.log('q1:', Math.round(q1_permanente * 1000) / 1000, 'q2: ', Math.round(q2_permanente * 1000) / 1000, 'q3: ', Math.round(q3_permanente * 1000) / 1000)

        let result_permanente = calculMatriceY_Trav(q1_permanente, q2_permanente, q3_permanente)
        fleche_permanente_y.value = result_permanente.fleche_max
        mydmax_permanente_y.value = result_permanente.mydmax
        tmax_permanente_y.value = result_permanente.tmax

        console.log('===============================');
    }

    function calculTravRDM_Z(){
        console.log('====== CALCUL TravRDM_Z ======')

        console.log("/// Charge de vent pression ///")

        let q1_pression = 0
        let q2_pression = 0 
        let q3_pression = 0
        console.log('q1: ', Math.round(q1_pression * 1000) / 1000, 'q2: ', Math.round(q2_pression * 1000) / 1000, 'q3: ', Math.round(q3_pression * 1000) / 1000)

        let result_pression = calculMatriceZ_Trav(q1_pression, q2_pression, q3_pression)
        fleche_pression_z.value = result_pression.fleche_max
        mydmax_pression_z.value = result_pression.mydmax
        tmax_pression_z.value = result_pression.tmax

        console.log("/// Charge de neige Sn ///")

        let q1_neige = Snz.value * (info.value.hauteur_total_chevetre / 2);
        let q2_neige = Snz.value * (info.value.hauteur_total_chevetre / 2) + (Snz.value * info.value.bande_charge_velux);
        let q3_neige = Snz.value * (info.value.hauteur_total_chevetre / 2);
        console.log('q1:', Math.round(q1_neige * 1000) / 1000, 'q2: ', Math.round(q2_neige * 1000) / 1000, 'q3: ', Math.round(q3_neige * 1000) / 1000)

        let result_neige = calculMatriceZ_Trav(q1_neige, q2_neige, q3_neige)
        fleche_neige_z.value = result_neige.fleche_max
        mydmax_neige_z.value = result_neige.mydmax
        tmax_neige_z.value = result_neige.tmax

        console.log("/// Charge permanente ///")

        let q1_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2)
        let q2_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2) + (PPtotZ.value * info.value.bande_charge_velux)
        let q3_permanente = info.value.masse_total_n * (info.value.hauteur_total_chevetre / 2)
        console.log('q1:', Math.round(q1_permanente * 1000) / 1000, 'q2: ', Math.round(q2_permanente * 1000) / 1000, 'q3: ', Math.round(q3_permanente * 1000) / 1000)

        let result_permanente = calculMatriceZ_Trav(q1_permanente, q2_permanente, q3_permanente)
        fleche_permanente_z.value = result_permanente.fleche_max
        mydmax_permanente_z.value = result_permanente.mydmax
        tmax_permanente_z.value = result_permanente.tmax

        console.log('==============================');
    }

    function calculTrav_ComELU(){
        console.log('====== CALCUL Trav_ComELU ======')

        let ref_arr = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente'},
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme'},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme'},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee'},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee'},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee'},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee'},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme'},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme'},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee'},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'permanente'},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'moyen_terme'},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'court_terme'},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee'},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee'},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee'},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee'},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee'},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee'},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee'},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'permanente'},
            {ref: 22, combinaisons: 'G', duree: 'permanente'},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme'},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme'},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee'},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee'},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee'},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee'},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme'},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme'},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'permanente'},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'moyen_terme'},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'court_terme'},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee'},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee'},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee'},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee'},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee'},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee'},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee'},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee'},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee'},
        ]
        
        console.log("/// Moments max sur l'axe Y ///")

        let G_Y = mydmax_permanente_y.value
        let Q_Y = 0
        let S_Y = mydmax_neige_y.value
        let W1_Y = mydmax_pression_y.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_elu_y = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', moment: 1.35 * G_Y , },
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', moment: 1.35 * G_Y + 1.5 * Q_Y},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 22, combinaisons: 'G', duree: 'permanente', moment: G_Y},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', moment: G_Y + 1.5 * Q_Y},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', moment: G_Y + 1.5 * S_Y},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', moment: G_Y + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
        ]

        let toFound = info.value.classe_service
        const found = kmod.value.find(kmod => kmod.classe_service === toFound && kmod.materiaux === 'BM')
        console.log(found)

        for (let index = 0; index < comb_elu_y.length; index++) {
            const element = comb_elu_y[index];
            comb_elu_y[index].kmod = found[element.duree]
            comb_elu_y[index].charge = comb_elu_y[index].moment / comb_elu_y[index].kmod
        }
        // console.table(comb_elu_y)
        
        console.log("/// Moments max sur l'axe Z ///")

        let G_Z = mydmax_permanente_z.value
        let Q_Z = 0
        let S_Z = mydmax_neige_z.value
        let W1_Z = mydmax_pression_z.value
        console.log('G: ', Math.round(G_Z * 1000) / 1000, 'Q: ', Q_Z, 'S: ', Math.round(S_Z * 100) / 100, 'W1: ', Math.round(W1_Z * 100) / 100)

        let comb_elu_z = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', moment: 1.35 * G_Z , },
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', moment: 1.35 * G_Z + 1.5 * Q_Z},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', moment: 1.35 * G_Z + 1.5 * Q_Z + 0.75 * S_Z},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * Q_Z + 0.9 * W1_Z},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * Q_Z + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * Q_Z + 0.75 * S_Z + 0.9 * W1_Z},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * Q_Z + 0.75 * S_Z + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', moment: 1.35 * G_Z + 1.5 * S_Z},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', moment: 1.35 * G_Z + 1.5 * S_Z + 1.05 * Q_Z},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * S_Z + 0.9 * W1_Z},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * S_Z + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * S_Z + 1.05 * Q_Z + 0.9 * W1_Z},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * S_Z + 1.05 * Q_Z + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * W1_Z},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * W1_Z + 1.05 * Q_Z},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * W1_Z + 0.75 * S_Z},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * W1_Z + 1.05 * Q_Z + 0.75 * S_Z},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * 0 + 1.05 * Q_Z},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * 0 + 0.75 * S_Z},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Z + 1.5 * 0 + 1.05 * Q_Z + 0.75 * S_Z},
            {ref: 22, combinaisons: 'G', duree: 'permanente', moment: G_Z},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', moment: G_Z + 1.5 * Q_Z},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', moment: G_Z + 1.5 * Q_Z + 0.75 * S_Z},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', moment: G_Z + 1.5 * Q_Z + 0.9 * W1_Z},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', moment: G_Z + 1.5 * Q_Z + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: G_Z + 1.5 * Q_Z + 0.75 * S_Z + 0.9 * W1_Z},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: G_Z + 1.5 * Q_Z + 0.75 * S_Z + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', moment: G_Z + 1.5 * S_Z},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', moment: G_Z + 1.5 * S_Z + 1.05 * Q_Z},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', moment: G_Z + 1.5 * S_Z + 0.9 * W1_Z},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', moment: G_Z + 1.5 * S_Z + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: G_Z + 1.5 * S_Z + 1.05 * Q_Z + 0.9 * W1_Z},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: G_Z + 1.5 * S_Z + 1.05 * Q_Z + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', moment: G_Z + 1.5 * W1_Z},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', moment: G_Z + 1.5 * W1_Z + 1.05 * Q_Z},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', moment: G_Z + 1.5 * W1_Z + 0.75 * S_Z},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: G_Z + 1.5 * W1_Z + 1.05 * Q_Z + 0.75 * S_Z},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', moment: G_Z + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', moment: G_Z + 1.5 * 0 + 1.05 * Q_Z},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', moment: G_Z + 1.5 * 0 + 0.75 * S_Z},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: G_Z + 1.5 * 0 + 1.05 * Q_Z + 0.75 * S_Z},
        ]

        for (let index = 0; index < comb_elu_z.length; index++) {
            const element = comb_elu_z[index];
            comb_elu_z[index].kmod = found[element.duree]
            comb_elu_z[index].charge = comb_elu_z[index].moment / comb_elu_z[index].kmod
        }
        // console.table(comb_elu_z)

        console.log("/// Axe Y ///")
        console.log("/// Combinaison la plus défavorable Y ///")
        let max_charge_y = Math.max(...comb_elu_y.map(o => o.charge))
        console.log('Max (Charge /Kmod) Y: ', Math.round(max_charge_y * 100) / 100)

        let ref_y = comb_elu_y.find(comb => comb.charge === max_charge_y)
        // console.table({
        //     'Combinaison': ref_y.combinaisons,
        //     'Durée de chargement': ref_y.duree,
        //     'Momen combiné (kN * M)': ref_y.moment,
        //     'Kmod': ref_y.kmod
        // })

        console.log("/// Vérification flexion Y ///")

        let inertie_y = info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) / 12
        let teta_y =  ref_y.moment * Math.pow(10, 6) / (inertie_y / (info.value.hauteur_section_chevetre / 2))
        let fm_y = ref_y.kmod * 18 / 1.3
        let n_y = teta_y / fm_y

        // console.table({
        //     'Inertie (mm4)': Math.round(inertie_y),
        //     'om,y,d (Mpa)': Math.round(teta_y * 100) / 100,
        //     'fm,y,d (Mpa)': Math.round(fm_y * 100) / 100,
        //     'n (%)': Math.round(n_y * 100),
        // })


        console.log("/// Axe Z ///")
        console.log("/// Combinaison la plus défavorable Z ///")
        let max_charge_z = Math.max(...comb_elu_z.map(o => o.charge))
        console.log('Max (Charge /Kmod) Z: ', Math.round(max_charge_z * 100) / 100)

        let ref_z = comb_elu_z.find(comb => comb.charge === max_charge_z)
        // console.table({
        //     'Combinaison': ref_z.combinaisons,
        //     'Durée de chargement': ref_z.duree,
        //     'Momen combiné (kN * M)': ref_z.moment,
        //     'Kmod': ref_z.kmod
        // })

        console.log("/// Vérification flexion Z ///")

        let inertie_z = info.value.hauteur_section_chevetre * Math.pow(info.value.largeur_section_chevetre, 3) / 12
        let teta_z =  ref_z.moment * Math.pow(10, 6) / (inertie_z / (info.value.largeur_section_chevetre / 2))
        let fm_z = ref_z.kmod * 18 / 1.3
        let n_z = teta_z / fm_z

        // console.table({
        //     'Inertie (mm4)': Math.round(inertie_z),
        //     'om,y,d (Mpa)': Math.round(teta_z * 100) / 100,
        //     'fm,y,d (Mpa)': Math.round(fm_z * 100) / 100,
        //     'n (%)': Math.round(n_z * 100),
        // })

        console.log("/// Total ///")

        n_defavorable.value = Math.max(n_y, n_z)
        console.log('n défavorable (%): ', Math.round(n_defavorable.value * 100))

        console.log('==============================');
    }

    function calculTrav_CombELS(){
        console.log('====== CALCUL Trav_CombELS ======')

        console.log("/// Flèches max sur L_trav'axe Y ///")

        let G_Y = fleche_permanente_y.value
        let Q_Y = 0
        let S_Y = fleche_neige_y.value
        let W1_Y = fleche_pression_y.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_els_y_carac = [
            {ref: 1, combinaisons: 'G + Q', fleche: G_Y + Q_Y},
            {ref: 2, combinaisons: 'G + S', fleche: G_Y + S_Y},
            {ref: 3, combinaisons: 'G + W(+)', fleche: G_Y + W1_Y},
            {ref: 4, combinaisons: 'G + S + 0.6W', fleche: G_Y + S_Y + 0.6 * W1_Y},
            {ref: 5, combinaisons: 'G + W + 0.5S', fleche: G_Y + W1_Y + 0.5 * S_Y },
        ]

        let comb_els_y_perm = [
            {ref: 1, combinaisons: 'G', fleche: G_Y },
        ]
        // console.table(comb_els_y_carac)

        console.log("/// Flèches max sur L_trav'axe Z ///")

        let G_Z = fleche_permanente_z.value
        let Q_Z = 0
        let S_Z = fleche_neige_z.value
        let W1_Z = fleche_pression_z.value
        console.log('G: ', Math.round(G_Z * 1000) / 1000, 'Q: ', Q_Z, 'S: ', Math.round(S_Z * 100) / 100, 'W1: ', Math.round(W1_Z * 100) / 100)

        console.log("/// AXE Y ///")
        let uinst_y = Math.max(...comb_els_y_carac.map(o => o.fleche))
        let ucreep_y = Math.max(...comb_els_y_perm.map(o => o.fleche))
        let unet_y = ucreep_y + uinst_y
        console.log('Uinst(Edc): ', Math.round(uinst_y * 1000) / 1000, 'Ucreep: ', Math.round(ucreep_y * 100) / 100, 'Unet,fin: ', Math.round(unet_y * 1000) / 1000)

        let comb_els_z_carac = [
            {ref: 1, combinaisons: 'G + Q', fleche: G_Z + Q_Z},
            {ref: 2, combinaisons: 'G + S', fleche: G_Z + S_Z},
            {ref: 3, combinaisons: 'G + W(+)', fleche: G_Z + W1_Z},
            {ref: 4, combinaisons: 'G + S + 0.6W', fleche: G_Z + S_Z + 0.6 * W1_Z},
            {ref: 5, combinaisons: 'G + W + 0.5S', fleche: G_Z + W1_Z + 0.5 * S_Z },
        ]

        let comb_els_z_perm = [
            {ref: 1, combinaisons: 'G', fleche: G_Z },
        ]
        // console.table(comb_els_z_carac)

        console.log("/// AXE Z ///")
        let uinst_z = Math.max(...comb_els_z_carac.map(o => o.fleche))
        let ucreep_z = Math.max(...comb_els_z_perm.map(o => o.fleche))
        let unet_z = ucreep_z + uinst_z
        console.log('Uinst(Edc): ', Math.round(uinst_z * 1000) / 1000, 'Ucreep: ', Math.round(ucreep_z * 1000) / 1000, 'Unet,fin: ', Math.round(unet_z * 1000) / 1000)

        console.log("/// Flèche Unet, fin (verticale) ///")
        
        let unet_max = Math.max(unet_y, unet_z)
        let fleche_limite = longueur_traverse.value / 500
        let n = unet_max / fleche_limite

        // console.table({
        //     'Unet, fin max': Math.round(unet_max * 100) / 100,
        //     'Flèche limite (L_trav/500)': Math.round(fleche_limite * 100) / 100,
        //     'n (%)': Math.round(n * 100),
        // })

        traverse_deformation.value = n

        if(n > 1){
            console.log('Section trop faible')
        } else {
            console.log('Section valide')
        }

        console.log('==============================');
    }

    function calculMont1_RDM_Y(){
        console.log('====== CALCUL Mont-1_RDM_Y ======')
        longueur_montant.value = info.value.hauteur_total_chevetre - 2 * info.value.largeur_section_chevetre
        console.log('Longueur Montant-1: ', longueur_montant)

        d1_mont.value = longueur_montant.value / 3
        d2_mont.value = d1_mont.value * 2
        L_mont.value = d1_mont.value * 3

        a1_mont.value = d1_mont.value
        a2_mont.value = d1_mont.value
        a3_mont.value = d1_mont.value
        console.log('d1_mont: ', d1_mont.value, 'd2_mont: ', d2_mont.value, 'L_mont: ', L_mont.value, 'a1_mont: ', a1_mont.value, 'a2_mont: ', a2_mont.value, 'a3_mont: ', a3_mont.value)

        console.log("/// Charge de vent pression ///")

        let q1_pression = FwN_result.value * (info.value.largeur_total_chevetre / 2) + (FwN_result.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4))
        let q2_pression = FwN_result.value * (info.value.largeur_total_chevetre / 2) + (FwN_result.value * ((longueur_traverse.value - info.value.largeur_total_chevetre)/ 4))
        let q3_pression = FwN_result.value * (info.value.largeur_total_chevetre / 2) + (FwN_result.value * ((longueur_traverse.value - info.value.largeur_total_chevetre)/ 4))
        console.log('q1: ', Math.round(q1_pression * 1000) / 1000, 'q2: ', Math.round(q2_pression * 1000) / 1000, 'q3: ', Math.round(q3_pression * 1000) / 1000)

        let result_pression = calculMatriceY_Mont(q1_pression, q2_pression, q3_pression)
        fleche_pression_y_mont.value = result_pression.fleche_max
        mydmax_pression_y_mont.value = result_pression.mydmax
        tmax_pression_y_mont.value = result_pression.tmax

        console.log("/// Charge de neige Sn ///")

        let q1_neige = Sny.value * (info.value.largeur_total_chevetre / 2) + (Sny.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4));
        let q2_neige = Sny.value * (info.value.largeur_total_chevetre / 2) + (Sny.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4));
        let q3_neige = Sny.value * (info.value.largeur_total_chevetre / 2) + (Sny.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4));
        console.log('q1:', Math.round(q1_neige * 1000) / 1000, 'q2: ', Math.round(q2_neige * 1000) / 1000, 'q3: ', Math.round(q3_neige * 1000) / 1000)

        let result_neige = calculMatriceY_Mont(q1_neige, q2_neige, q3_neige)
        fleche_neige_y_mont.value = result_neige.fleche_max
        mydmax_neige_y_mont.value = result_neige.mydmax
        tmax_neige_y_mont.value = result_neige.tmax

        console.log("/// Charge permanente ///")

        let q1_permanente = info.value.masse_total_n * (info.value.largeur_total_chevetre / 2) + (PPtotY.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4))
        let q2_permanente = info.value.masse_total_n * (info.value.largeur_total_chevetre / 2) + (PPtotY.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4))
        let q3_permanente = info.value.masse_total_n * (info.value.largeur_total_chevetre / 2) + (PPtotY.value * ((longueur_traverse.value - info.value.largeur_total_chevetre) / 4))
        console.log('q1:', Math.round(q1_permanente * 1000) / 1000, 'q2: ', Math.round(q2_permanente * 1000) / 1000, 'q3: ', Math.round(q3_permanente * 1000) / 1000)

        let result_permanente = calculMatriceY_Mont(q1_permanente, q2_permanente, q3_permanente)
        fleche_permanente_y_mont.value = result_permanente.fleche_max
        mydmax_permanente_y_mont.value = result_permanente.mydmax
        tmax_permanente_y_mont.value = result_permanente.tmax

        console.log('===============================');
    }

    function calculMont1_ComELU(){
        console.log('====== CALCUL Mont1_ComELU ======')
        
        console.log("/// Moments max sur l'axe Y ///")

        let G_Y = mydmax_permanente_y_mont.value
        let Q_Y = 0
        let S_Y = mydmax_neige_y_mont.value
        let W1_Y = mydmax_pression_y_mont.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_elu_y = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', moment: 1.35 * G_Y},
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', moment: 1.35 * G_Y + 1.5 * Q_Y},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 22, combinaisons: 'G', duree: 'permanente', moment: G_Y},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', moment: G_Y + 1.5 * Q_Y},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', moment: G_Y + 1.5 * S_Y},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', moment: G_Y + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
        ]

        let toFound = info.value.classe_service
        const found = kmod.value.find(kmod => kmod.classe_service === toFound && kmod.materiaux === 'BM')
        console.log(found)

        for (let index = 0; index < comb_elu_y.length; index++) {
            const element = comb_elu_y[index];
            comb_elu_y[index].kmod = found[element.duree]
            comb_elu_y[index].charge = comb_elu_y[index].moment / comb_elu_y[index].kmod
        }
        // console.table(comb_elu_y)

        console.log("/// Tranchant max sur l'axe Y ///")

        let G_Yt = tmax_permanente_y_mont.value
        let Q_Yt = 0
        let S_Yt = tmax_neige_y_mont.value
        let W1_Yt = tmax_pression_y_mont.value
        console.log('G: ', Math.round(G_Yt * 1000) / 1000, 'Q: ', Q_Yt, 'S: ', Math.round(S_Yt * 100) / 100, 'W1: ', Math.round(W1_Yt * 100) / 100)

        let comb_elu_yt = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', tranchant: 1.35 * G_Yt , },
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.9 * W1_Yt},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * W1_Yt},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * S_Yt},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 0.9 * W1_Yt},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * W1_Yt},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 0.75 * S_Yt},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 1.05 * Q_Yt},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 0.75 * S_Yt},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 22, combinaisons: 'G', duree: 'permanente', tranchant: G_Yt},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', tranchant: G_Yt + 1.5 * Q_Yt},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.9 * W1_Yt},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * W1_Yt},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', tranchant: G_Yt + 1.5 * S_Yt},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 0.9 * W1_Yt},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * W1_Yt},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 0.75 * S_Yt},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 1.05 * Q_Yt},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 0.75 * S_Yt},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 1.05 * Q_Yt + 0.75 * S_Yt},
        ]

        for (let index = 0; index < comb_elu_yt.length; index++) {
            const element = comb_elu_yt[index];
            comb_elu_yt[index].kmod = found[element.duree]
            comb_elu_yt[index].charge = comb_elu_yt[index].tranchant / comb_elu_yt[index].kmod
        }
        // console.table(comb_elu_yt)

        console.log("/// === Flexion === ///")
        console.log("/// Combinaison la plus défavorable Flexion ///")
        let max_charge_y = Math.max(...comb_elu_y.map(o => o.charge))
        console.log('Max (Charge /Kmod) Y: ', Math.round(max_charge_y * 100) / 100)

        let ref_y = comb_elu_y.find(comb => comb.charge === max_charge_y)
        // console.table({
        //     'Combinaison': ref_y.combinaisons,
        //     'Durée de chargement': ref_y.duree,
        //     'Momen combiné (kN * M)': ref_y.moment,
        //     'Kmod': ref_y.kmod
        // })

        console.log("/// Vérification flexion ///")

        let inertie_y = info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) / 12
        let teta_y =  ref_y.moment * Math.pow(10, 6) / (inertie_y / (info.value.hauteur_section_chevetre / 2))
        let fm_y = ref_y.kmod * 18 / 1.3
        let n_y = teta_y / fm_y

        // console.table({
        //     'Inertie (mm4)': Math.round(inertie_y),
        //     'om,y,d (Mpa)': Math.round(teta_y * 100) / 100,
        //     'fm,y,d (Mpa)': Math.round(fm_y * 100) / 100,
        //     'n (%)': Math.round(n_y * 100),
        // })

        montant_flexion.value = n_y

        console.log("/// === Cisaillement === ///")
        console.log("/// Combinaison la plus défavorable Cisaillement ///")
        
        let max_charge_yt = Math.max(...comb_elu_yt.map(o => o.charge))
        console.log('Max (Charge /Kmod) Z: ', Math.round(max_charge_yt * 100) / 100)

        let ref_yt = comb_elu_yt.find(comb => comb.charge === max_charge_yt)
        // console.table({
        //     'Combinaison': ref_yt.combinaisons,
        //     'Durée de chargement': ref_yt.duree,
        //     'Trancahnt combiné (kN * M)': ref_yt.tranchant,
        //     'Kmod': ref_yt.kmod
        // })

        console.log("/// Vérification flexion ///")

        let inertie_z = info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) / 12
        let teta_z =  3 / 2 * (ref_yt.tranchant * Math.pow(10,3) / (0.67 * info.value.largeur_section_chevetre * info.value.hauteur_section_chevetre))
        let fm_z = ref_yt.kmod * 3.5 / 1.3
        let n_z = teta_z / fm_z

        // console.table({
        //     'Inertie (mm4)': Math.round(inertie_z),
        //     'om,y,d (Mpa)': Math.round(teta_z * 100) / 100,
        //     'fm,y,d (Mpa)': Math.round(fm_z * 100) / 100,
        //     'n (%)': Math.round(n_z * 100),
        // })
    
        montant_cisaillement.value = n_z

        console.log('==============================');
    }

    function calculMont1_CombELS(){
        console.log('====== CALCUL Mont1_CombELS ======')

        console.log("/// Flèches max sur l'axe Y ///")

        let G_Y = fleche_permanente_y_mont.value
        let Q_Y = 0
        let S_Y = fleche_neige_y_mont.value
        let W1_Y = fleche_pression_y_mont.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_els_y_carac = [
            {ref: 1, combinaisons: 'G + Q', fleche: G_Y + Q_Y},
            {ref: 2, combinaisons: 'G + S', fleche: G_Y + S_Y},
            {ref: 3, combinaisons: 'G + W(+)', fleche: G_Y + W1_Y},
            {ref: 4, combinaisons: 'G + S + 0.6W', fleche: G_Y + S_Y + 0.6 * W1_Y},
            {ref: 5, combinaisons: 'G + W + 0.5S', fleche: G_Y + W1_Y + 0.5 * S_Y },
        ]

        let comb_els_y_perm = [
            {ref: 1, combinaisons: 'G', fleche: G_Y },
        ]
        // console.table(comb_els_y_carac)

        console.log("/// Flèche final Axe Y ///")
        let uinst_y = Math.max(...comb_els_y_carac.map(o => o.fleche))
        let ucreep_y = Math.max(...comb_els_y_perm.map(o => o.fleche))
        let unet_y = ucreep_y + uinst_y
        let fleche_limite = longueur_montant.value / 150
        let n = unet_y / fleche_limite
        // console.table({
        //     'Uinst(Edc)': Math.round(uinst_y * 1000) / 1000,
        //     'Ucreep': Math.round(ucreep_y * 100) / 100,
        //     'Unet, fin': Math.round(unet_y * 1000) / 1000,
        //     'Flèche limite (L/500)': Math.round(fleche_limite * 100) / 100,
        //     'n (%)': Math.round(n * 100)
        // })

        montant_deformation.value = n

        console.log('==============================');
    }

    function calculMont2_RDM_Y(){
        console.log('====== CALCUL Mont-2_RDM_Y ======')
        longueur_montant2.value = info.value.entraxe_panne
        console.log('Longueur Montant-1: ', longueur_montant2.value)

        d1_mont2.value = info.value.h1
        d2_mont2.value = info.value.h1 + info.value.hauteur_total_chevetre
        L_mont2.value = longueur_montant2.value

        a1_mont2.value = d1_mont2.value
        a2_mont2.value = d2_mont2.value - d1_mont2.value
        a3_mont2.value = L_mont2.value - d2_mont2.value
        console.log('d1_mont2: ', d1_mont2.value, 'd2_mont2: ', d2_mont2.value, 'L_mont2: ', L_mont2.value, 'a1_mont2: ', a1_mont2.value, 'a2_mont2: ', a2_mont2.value, 'a3_mont2: ', a3_mont2.value)

        console.log("/// Charge de vent pression ///")

        let q1_pression = FwN_result.value * info.value.entraxe_chevron
        let q2_pression = FwN_result.value * ((longueur_traverse.value / 2 * (0.5 * info.value.h2 + info.value.hauteur_total_chevetre + 0.5 * info.value.h1)) / info.value.hauteur_velux)
        let q3_pression = FwN_result.value * info.value.entraxe_chevron
        console.log('q1: ', Math.round(q1_pression * 1000) / 1000, 'q2: ', Math.round(q2_pression * 1000) / 1000, 'q3: ', Math.round(q3_pression * 1000) / 1000)

        let result_pression = calculMatriceY_Mont2(q1_pression, q2_pression, q3_pression)
        fleche_pression_y_mont2.value = result_pression.fleche_max
        mydmax_pression_y_mont2.value = result_pression.mydmax
        tmax_pression_y_mont2.value = result_pression.tmax

        console.log("/// Charge de neige Sn ///")

        let q1_neige = Sny.value * info.value.entraxe_chevron
        let q2_neige = Sny.value * ((longueur_traverse.value / 2 * (0.5 * info.value.h2 + info.value.hauteur_total_chevetre + 0.5 * info.value.h1)) / info.value.hauteur_velux)
        let q3_neige = Sny.value * info.value.entraxe_chevron
        console.log('q1:', Math.round(q1_neige * 1000) / 1000, 'q2: ', Math.round(q2_neige * 1000) / 1000, 'q3: ', Math.round(q3_neige * 1000) / 1000)

        let result_neige = calculMatriceY_Mont2(q1_neige, q2_neige, q3_neige)
        fleche_neige_y_mont2.value = result_neige.fleche_max
        mydmax_neige_y_mont2.value = result_neige.mydmax
        tmax_neige_y_mont2.value = result_neige.tmax

        console.log("/// Charge permanente ///")

        let q1_permanente = PPtotY.value * info.value.entraxe_chevron
        let q2_permanente = ((PPtotY.value * (longueur_traverse.value / 2 * (0.5 * info.value.h2 + 0.5 * info.value.h1))) + (info.value.masse_total_n * (info.value.hauteur_total_chevetre * longueur_traverse.value / 2))) / info.value.hauteur_total_chevetre
        let q3_permanente = PPtotY.value * info.value.entraxe_chevron
        console.log('q1:', Math.round(q1_permanente * 1000) / 1000, 'q2: ', Math.round(q2_permanente * 1000) / 1000, 'q3: ', Math.round(q3_permanente * 1000) / 1000)

        let result_permanente = calculMatriceY_Mont2(q1_permanente, q2_permanente, q3_permanente)
        fleche_permanente_y_mont2.value = result_permanente.fleche_max
        mydmax_permanente_y_mont2.value = result_permanente.mydmax
        tmax_permanente_y_mont2.value = result_permanente.tmax

        console.log('===============================');
    }

    function calculMont2_ComELU(){
        console.log('====== CALCUL Mont2_ComELU ======')
        
        console.log("/// Moments max sur l'axe Y ///")

        let G_Y = mydmax_permanente_y_mont2.value
        let Q_Y = 0
        let S_Y = mydmax_neige_y_mont2.value
        let W1_Y = mydmax_pression_y_mont2.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_elu_y = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', moment: 1.35 * G_Y},
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', moment: 1.35 * G_Y + 1.5 * Q_Y},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: 1.35 * G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 22, combinaisons: 'G', duree: 'permanente', moment: G_Y},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', moment: G_Y + 1.5 * Q_Y},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * W1_Y},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * W1_Y},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * Q_Y + 0.75 * S_Y + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', moment: G_Y + 1.5 * S_Y},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * W1_Y},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * W1_Y},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', moment: G_Y + 1.5 * S_Y + 1.05 * Q_Y + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 0.75 * S_Y},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * W1_Y + 1.05 * Q_Y + 0.75 * S_Y},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', moment: G_Y + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 0.75 * S_Y},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', moment: G_Y + 1.5 * 0 + 1.05 * Q_Y + 0.75 * S_Y},
        ]

        let toFound = info.value.classe_service
        const found = kmod.value.find(kmod => kmod.classe_service === toFound && kmod.materiaux === 'BM')
        console.log(found)

        for (let index = 0; index < comb_elu_y.length; index++) {
            const element = comb_elu_y[index];
            comb_elu_y[index].kmod = found[element.duree]
            comb_elu_y[index].charge = comb_elu_y[index].moment / comb_elu_y[index].kmod
        }
        // console.table(comb_elu_y)

        console.log("/// Tranchant max sur l'axe Y ///")

        let G_Yt = tmax_permanente_y_mont2.value
        let Q_Yt = 0
        let S_Yt = tmax_neige_y_mont2.value
        let W1_Yt = tmax_pression_y_mont2.value
        console.log('G: ', Math.round(G_Yt * 1000) / 1000, 'Q: ', Q_Yt, 'S: ', Math.round(S_Yt * 100) / 100, 'W1: ', Math.round(W1_Yt * 100) / 100)

        let comb_elu_yt = [
            {ref: 1, combinaisons: '1.35G', duree: 'permanente', tranchant: 1.35 * G_Yt , },
            {ref: 2, combinaisons: '1.35G+1.5Q', duree: 'moyen_terme', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt},
            {ref: 3, combinaisons: '1.35G+1.5Q+0.75S', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt},
            {ref: 4, combinaisons: '1.35G+1.5Q+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.9 * W1_Yt},
            {ref: 5, combinaisons: '1.35G+1.5Q+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.9 * 0},
            {ref: 6, combinaisons: '1.35G+1.5Q+0.75S+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * W1_Yt},
            {ref: 7, combinaisons: '1.35G+1.5Q+0.75S+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * 0},
            {ref: 8, combinaisons: '1.35G+1.5S', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * S_Yt},
            {ref: 9, combinaisons: '1.35G+1.5S+1.05Q', duree: 'court_terme', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt},
            {ref: 10, combinaisons: '1.35G+1.5S+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 0.9 * W1_Yt},
            {ref: 11, combinaisons: '1.35G+1.5S+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 0.9 * 0},
            {ref: 12, combinaisons: '1.35G+1.5S+1.05Q+0.9W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * W1_Yt},
            {ref: 13, combinaisons: '1.35G+1.5S+1.05Q+0.9W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * 0},
            {ref: 14, combinaisons: '1.35G+1.5W', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt},
            {ref: 15, combinaisons: '1.35G+1.5W+1.05Q', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt},
            {ref: 16, combinaisons: '1.35G+1.5W+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 0.75 * S_Yt},
            {ref: 17, combinaisons: '1.35G+1.5W+1.05Q+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 18, combinaisons: '1.35G+1.5W2', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0},
            {ref: 19, combinaisons: '1.35G+1.5W2+1.05Q', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 1.05 * Q_Yt},
            {ref: 20, combinaisons: '1.35G+1.5W2+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 0.75 * S_Yt},
            {ref: 21, combinaisons: '1.35G+1.5W2+1.05Q+0.75S', duree: 'instantanee', tranchant: 1.35 * G_Yt + 1.5 * 0 + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 22, combinaisons: 'G', duree: 'permanente', tranchant: G_Yt},
            {ref: 23, combinaisons: 'G+1.5Q', duree: 'moyen_terme', tranchant: G_Yt + 1.5 * Q_Yt},
            {ref: 24, combinaisons: 'G+1.5Q+0.75S', duree: 'court_terme', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt},
            {ref: 25, combinaisons: 'G+1.5Q+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.9 * W1_Yt},
            {ref: 26, combinaisons: 'G+1.5Q+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.9 * 0},
            {ref: 27, combinaisons: 'G+1.5Q+0.75S+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * W1_Yt},
            {ref: 28, combinaisons: 'G+1.5Q+0.75S+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * Q_Yt + 0.75 * S_Yt + 0.9 * 0},
            {ref: 29, combinaisons: 'G+1.5S', duree: 'court_terme', tranchant: G_Yt + 1.5 * S_Yt},
            {ref: 30, combinaisons: 'G+1.5S+1.05Q', duree: 'court_terme', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt},
            {ref: 31, combinaisons: 'G+1.5S+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 0.9 * W1_Yt},
            {ref: 32, combinaisons: 'G+1.5S+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 0.9 * 0},
            {ref: 33, combinaisons: 'G+1.5S+1.05Q+0.9W', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * W1_Yt},
            {ref: 34, combinaisons: 'G+1.5S+1.05Q+0.9W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * S_Yt + 1.05 * Q_Yt + 0.9 * 0},
            {ref: 35, combinaisons: 'G+1.5W', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt},
            {ref: 36, combinaisons: 'G+1.5W+1.05Q', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt},
            {ref: 37, combinaisons: 'G+1.5W+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 0.75 * S_Yt},
            {ref: 38, combinaisons: 'G+1.5W+1.05Q+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * W1_Yt + 1.05 * Q_Yt + 0.75 * S_Yt},
            {ref: 39, combinaisons: 'G+1.5W2', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0},
            {ref: 40, combinaisons: 'G+1.5W2+1.05Q', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 1.05 * Q_Yt},
            {ref: 41, combinaisons: 'G+1.5W2+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 0.75 * S_Yt},
            {ref: 42, combinaisons: 'G+1.5W2+1.05Q+0.75S', duree: 'instantanee', tranchant: G_Yt + 1.5 * 0 + 1.05 * Q_Yt + 0.75 * S_Yt},
        ]

        for (let index = 0; index < comb_elu_yt.length; index++) {
            const element = comb_elu_yt[index];
            comb_elu_yt[index].kmod = found[element.duree]
            comb_elu_yt[index].charge = comb_elu_yt[index].tranchant / comb_elu_yt[index].kmod
        }
        // console.table(comb_elu_yt)

        console.log("/// === Flexion === ///")
        console.log("/// Combinaison la plus défavorable Flexion ///")
        let max_charge_y = Math.max(...comb_elu_y.map(o => o.charge))
        console.log('Max (Charge /Kmod) Y: ', Math.round(max_charge_y * 100) / 100)

        let ref_y = comb_elu_y.find(comb => comb.charge === max_charge_y)
        // console.table({
        //     'Combinaison': ref_y.combinaisons,
        //     'Durée de chargement': ref_y.duree,
        //     'Momen combiné (kN * M)': ref_y.moment,
        //     'Kmod': ref_y.kmod
        // })

        console.log("/// Vérification flexion ///")

        let inertie_y = info.value.largeur_chevron * Math.pow(info.value.hauteur_chevron, 3) / 12
        let teta_y =  ref_y.moment * Math.pow(10, 6) / (inertie_y / (info.value.hauteur_chevron / 2))
        let fm_y = ref_y.kmod * 18 / 1.3
        let n_y = teta_y / fm_y

        console.table({
            'Inertie (mm4)': Math.round(inertie_y),
            'om,y,d (Mpa)': Math.round(teta_y * 100) / 100,
            'fm,y,d (Mpa)': Math.round(fm_y * 100) / 100,
            'n (%)': Math.round(n_y * 100),
        })

        chevron_ext_flexion.value = n_y

        console.log("/// === Cisaillement === ///")
        console.log("/// Combinaison la plus défavorable Cisaillement ///")
        
        let max_charge_yt = Math.max(...comb_elu_yt.map(o => o.charge))
        console.log('Max (Charge /Kmod) Z: ', Math.round(max_charge_yt * 100) / 100)

        let ref_yt = comb_elu_yt.find(comb => comb.charge === max_charge_yt)
        console.table({
            'Combinaison': ref_yt.combinaisons,
            'Durée de chargement': ref_yt.duree,
            'Trancahnt combiné (kN * M)': ref_yt.tranchant,
            'Kmod': ref_yt.kmod
        })

        console.log("/// Vérification flexion ///")

        let inertie_z = info.value.largeur_chevron * Math.pow(info.value.hauteur_chevron, 3) / 12
        let teta_z =  3 / 2 * (ref_yt.tranchant * Math.pow(10,3) / (0.67 * info.value.largeur_chevron * info.value.hauteur_chevron))
        let fm_z = ref_yt.kmod * 3.5 / 1.3
        let n_z = teta_z / fm_z

        chevron_ext_flexion_cisaillement.value = n_z

        console.table({
            'Inertie (mm4)': Math.round(inertie_z),
            'om,y,d (Mpa)': Math.round(teta_z * 100) / 100,
            'fm,y,d (Mpa)': Math.round(fm_z * 100) / 100,
            'n (%)': Math.round(n_z * 100),
        })
    
        console.log('==============================');
    }

    function calculMont2_CombELS(){
        console.log('====== CALCUL Mont2_CombELS ======')

        console.log("/// Flèches max sur l'axe Y ///")

        let G_Y = fleche_permanente_y_mont2.value
        let Q_Y = 0
        let S_Y = fleche_neige_y_mont2.value
        let W1_Y = fleche_pression_y_mont2.value
        console.log('G: ', Math.round(G_Y * 1000) / 1000, 'Q: ', Q_Y, 'S: ', Math.round(S_Y * 100) / 100, 'W1: ', Math.round(W1_Y * 100) / 100)

        let comb_els_y_carac = [
            {ref: 1, combinaisons: 'G + Q', fleche: G_Y + Q_Y},
            {ref: 2, combinaisons: 'G + S', fleche: G_Y + S_Y},
            {ref: 3, combinaisons: 'G + W(+)', fleche: G_Y + W1_Y},
            {ref: 4, combinaisons: 'G + S + 0.6W', fleche: G_Y + S_Y + 0.6 * W1_Y},
            {ref: 5, combinaisons: 'G + W + 0.5S', fleche: G_Y + W1_Y + 0.5 * S_Y },
        ]

        let comb_els_y_perm = [
            {ref: 1, combinaisons: 'G', fleche: G_Y },
        ]
        // console.table(comb_els_y_carac)

        console.log("/// Flèche final Axe Y ///")
        let uinst_y = Math.max(...comb_els_y_carac.map(o => o.fleche))
        let ucreep_y = Math.max(...comb_els_y_perm.map(o => o.fleche))
        let unet_y = ucreep_y + uinst_y
        let fleche_limite = longueur_montant2.value / 300
        let n = unet_y / fleche_limite
        // console.table({
        //     'Uinst(Edc)': Math.round(uinst_y * 1000) / 1000,
        //     'Ucreep': Math.round(ucreep_y * 100) / 100,
        //     'Unet, fin': Math.round(unet_y * 1000) / 1000,
        //     'Flèche limite (L/500)': Math.round(fleche_limite * 100) / 100,
        //     'n (%)': Math.round(n * 100)
        // })

        chevron_ext_deformation.value = n

        console.log('==============================');
    }

    function calculInitOptimisation(){
        return new Promise((resolve, reject) => {
            loading.value = true
            console.log('====== Optimisation ======')
            
            let largeur_ut = info.value.largeur_section_chevetre
            init_largeur_section_chevetre.value = info.value.largeur_section_chevetre
            let largeur_max = 2000
            let largeur_mini = 45
            let increment = 2
    
            // console.table({
            //     'largeur_ut': largeur_ut,
            //     'Largeur max': largeur_max,
            //     'Largeur min': largeur_mini,
            //     'Incrément': increment
            // })
    
            // console.table({
            //     'Hauteur (mm)': info.value.hauteur_section_chevetre,
            //     'Largeur opti': 0,
            // })
    
            let chevetre = {
                traverse_chevetre : {
                    flexion: n_defavorable.value,
                    cisaillement: '/',
                    deformation: traverse_deformation.value,
                },
                montant_chevetre : {
                    flexion: montant_flexion.value,
                    cisaillement: montant_cisaillement.value,
                    deformation: montant_deformation.value,
                }
            }
    
            console.table({
                traverse_chevetre : {
                    flexion: Math.round(n_defavorable.value * 100),
                    cisaillement: '/',
                    deformation: Math.round(traverse_deformation.value * 100),
                },
                montant_chevetre : {
                    flexion: Math.round(montant_flexion.value * 100),
                    cisaillement: Math.round(montant_cisaillement.value * 100),
                    deformation: Math.round(montant_deformation.value * 100),
                }
            })
    
            let chevron_ext = {
                flexion: chevron_ext_flexion.value,
                cisaillement: chevron_ext_flexion_cisaillement.value,
                deformation: chevron_ext_deformation.value,
            }
    
            // console.table({
            //     flexion: Math.round(chevron_ext_flexion.value * 100),
            //     cisaillement: Math.round(chevron_ext_flexion_cisaillement.value * 100),
            //     deformation: Math.round(chevron_ext_deformation.value * 100),
            // })
    
    
            let arr_chevetre_traverse =
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevetre.traverse_chevetre.flexion + 0.05) * 100) ,
                cisaillement: '/',
                deformation: Math.round((chevetre.traverse_chevetre.deformation + 0.05) * 100),
            }
    
            let arr_chevetre_montant = 
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevetre.montant_chevetre.flexion + 0.05) * 100),
                cisaillement: Math.round((chevetre.montant_chevetre.cisaillement + 0.05) * 100),
                deformation: Math.round((chevetre.montant_chevetre.deformation + 0.05) * 100),
            }
    
            let arr_chevetre_ext = 
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevron_ext.flexion + 0.05) * 100),
                cisaillement: Math.round((chevron_ext.cisaillement + 0.05) * 100),
                deformation: Math.round((chevron_ext.deformation + 0.05) * 100),
            }
    
            arr_optimisation.value = [{
                chevetre : {
                    arr_chevetre_traverse, 
                    arr_chevetre_montant
                },
                chevron_ext: {
                    arr_chevetre_ext
                }
            }]

            initial.value = arr_optimisation.value
    
            currentLargeur.value = largeur_mini
            init.value = true        
            console.log(arr_optimisation.value)
            console.log(currentLargeur.value)
            resolve()
            console.log('==============================');
        })
    }

    function calculOptimisation(){
        return new Promise((resolve, reject) => {
            console.log('====== Optimisation++  ======')
            let largeur_ut = info.value.largeur_section_chevetre
    
            let chevetre = {
                traverse_chevetre : {
                    flexion: n_defavorable.value,
                    cisaillement: '/',
                    deformation: traverse_deformation.value,
                },
                montant_chevetre : {
                    flexion: montant_flexion.value,
                    cisaillement: montant_cisaillement.value,
                    deformation: montant_deformation.value,
                }
            }
    
            // console.table({
            //     traverse_chevetre : {
            //         flexion: Math.round(n_defavorable.value * 100),
            //         cisaillement: '/',
            //         deformation: Math.round(traverse_deformation.value * 100),
            //     },
            //     montant_chevetre : {
            //         flexion: Math.round(montant_flexion.value * 100),
            //         cisaillement: Math.round(montant_cisaillement.value * 100),
            //         deformation: Math.round(montant_deformation.value * 100),
            //     }
            // })
    
            let chevron_ext = {
                flexion: chevron_ext_flexion.value,
                cisaillement: chevron_ext_flexion_cisaillement.value,
                deformation: chevron_ext_deformation.value,
            }   
    
            let arr_chevetre_traverse = 
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevetre.traverse_chevetre.flexion + 0.05) * 100) ,
                cisaillement: '/',
                deformation: Math.round((chevetre.traverse_chevetre.deformation + 0.05) * 100),
            }
    
            let arr_chevetre_montant =
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevetre.montant_chevetre.flexion + 0.05) * 100),
                cisaillement: Math.round((chevetre.montant_chevetre.cisaillement + 0.05) * 100),
                deformation: Math.round((chevetre.montant_chevetre.deformation + 0.05) * 100),
            }
    
            let arr_chevetre_ext = 
            {
                largeur_piece: largeur_ut,
                flexion: Math.round((chevron_ext.flexion + 0.05) * 100),
                cisaillement: Math.round((chevron_ext.cisaillement + 0.05) * 100),
                deformation: Math.round((chevron_ext.deformation + 0.05) * 100),
            }

            arr_optimisation.value.push({
                chevetre : {
                    arr_chevetre_traverse, 
                    arr_chevetre_montant
                },
                chevron_ext: {
                    arr_chevetre_ext
                }
            })
            console.log(arr_optimisation.value)

            if(chevetre.traverse_chevetre.flexion + 0.05 < 1 && chevetre.traverse_chevetre.deformation + 0.05 < 1 && chevetre.montant_chevetre.flexion + 0.05 < 1 && chevetre.montant_chevetre.cisaillement + 0.05 < 1 && chevetre.montant_chevetre.deformation + 0.05){
                largeur_opti.value = largeur_ut
                console.log(largeur_opti.value)
                let found = arr_optimisation.value.find(p => p.chevetre.arr_chevetre_montant.largeur_piece === largeur_ut)
                best.value = found
                loading.value = false;
                console.log(found);
            } else {
                currentLargeur.value = currentLargeur.value + 2
            }
            resolve()
        })
    }

    function calculMatriceY_Trav(q1, q2, q3){
        let constante_1 = (q1 * (Math.pow(L_trav.value, 4) / 24));
        let constante_1_2 = constante_1 - (q1 - q2) * (Math.pow((L_trav.value - d1_trav.value), 4) / 24)
        let constante_1_3 = constante_1_2 - (q2 - q3) * (Math.pow((L_trav.value - d2_trav.value), 4) / 24)
        let constante_1_4 = constante_1_3

        let constante_2 = q1 * d1_trav.value 
        let constante_2_2 = constante_2 + q2 * (d2_trav.value - d1_trav.value)
        let constante_2_3 = constante_2_2 + q3 * (L_trav.value - d2_trav.value)
        let constante_2_4 = constante_2_3

        let constante_3 = q1 * Math.pow(d1_trav.value, 2) / 2
        let constante_3_2 = constante_3 + q2 * (d2_trav.value - d1_trav.value) * (d1_trav.value + (d2_trav.value - d1_trav.value)/2)
        let constante_3_3 = constante_3_2 + q3 * (longueur_traverse.value - d2_trav.value) * (d2_trav.value + (L_trav.value - d2_trav.value)/2) 
        // console.table('Constante', Math.round(constante_1_4), Math.round(constante_2_4 * 1000) / 1000, Math.round(constante_3_3 * 10000) / 10000)

        let matrice_1 = [
            [L_trav.value, Math.pow(L_trav.value, 3) / 6, 0],
            [0, 1, 1],
            [0, 0, L_trav.value]
        ]
        let resultMatrice1 = determinant(matrice_1)
        console.log('Determinant Matrice 1: ', Math.round(resultMatrice1))

        let matrice_2 = [
            [constante_1_4, Math.pow(L_trav.value, 3) / 6, 0],
            [constante_2_4, 1, 1],
            [constante_3_3, 0, L_trav.value]
        ]
        let resultMatrice2 = determinant(matrice_2)
        console.log('Determinant Matrice 2: ', Math.round(resultMatrice2))

        let matrice_3 = [
            [L_trav.value, constante_1_4, 0],
            [0, constante_2_4, 1],
            [0, constante_3_3, L_trav.value],
        ]
        let resultMatrice3 = determinant(matrice_3)
        console.log('Determinant Matrice 3: ', Math.round(resultMatrice3))

        let matrice_4 = [
            [L_trav.value, Math.pow(L_trav.value, 3) / 6, constante_1_4],
            [0, 1, constante_2_4],
            [0, 0 , constante_3_3]
        ]

        let resultMatrice4 = determinant(matrice_4)
        console.log('Determinant Matrice 4: ', Math.round(resultMatrice4))

        let Ely0 = resultMatrice2 / resultMatrice1
        let Ya = resultMatrice3 / resultMatrice1
        let Yb = resultMatrice4 / resultMatrice1
        console.log('El y\'(0): ', Math.round(Ely0), 'Ya: ', Math.round(Ya * 100000) / 100000, 'Yb: ', Math.round(Yb * 100000) / 100000)

        let table = []
        let nice_table = []
        // {x: 0,  ely: 0,yx: 0,mydx: 0,tx: 0,forcex: 0}
        table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Ya - q1 * 0,
            forcex: 0
        })

        nice_table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Math.round((Ya - q1 * 0) * 10000000) / 10000000,
            forcex: 0
        })

        let i = 0
        let x = 0
        while(i < 150){
            i++
            let X
            let elyR
            let yxR
            let mydxR
            let txR
            let forcexR

            if(i > 100 && i < 151){
                X = x + a3_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_trav.value), 4) / 24 - q2 * Math.pow((X - d1_trav.value), 4) / 24 + q2 * Math.pow((X - d2_trav.value), 4) / 24 - q3 * Math.pow((X - d2_trav.value), 4) / 24; 
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_trav.value), 2) / 2 - q2 * Math.pow((X - d1_trav.value), 2) / 2 + q2 * Math.pow((X - d2_trav.value), 2) / 2 - q3 * Math.pow((X - d2_trav.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_trav.value) - q2 * (X - d1_trav.value) + q2 * (X - d2_trav.value) - q3 * (X - d2_trav.value)
                forcexR = q3
            } else if(i > 50 && i < 101){
                X = x + a2_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_trav.value), 4) / 24 - q2 * Math.pow((X - d1_trav.value), 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_trav.value), 2) / 2 - q2 * Math.pow((X - d1_trav.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_trav.value) - q2 * (X - d1_trav.value)
                forcexR = q2
            } else {
                X = x + a1_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2
                txR = Ya - q1 * X
                forcexR = q1
            }
            x = X;

            table.push({
                x: X, 
                ely: elyR,
                yx: yxR,
                mydx: mydxR,
                tx: txR,
                forcex: forcexR,
            })

            nice_table.push({
                x: Math.round(X * 10) / 10, 
                ely: Math.round(elyR * 10) / 10,
                yx: Math.round(yxR * 1000000) / 1000000,
                mydx: Math.round(mydxR * 10000) / 10000,
                tx: Math.round(txR * 10000000) / 10000000,
                forcex: Math.round(forcexR * 1000) / 1000,
            })
        }   
        // console.table(nice_table)

        let fleche_max = Math.max(Math.abs(Math.min(...table.map(o => o.yx))) ,...table.map(o => o.yx))
        console.log('Flèche Max: ', Math.round(fleche_max * 100) / 100)

        let MydMax = Math.max(...table.map(o => o.mydx)) * Math.pow(10, -6)
        console.log('Myd max: ', Math.round(MydMax * 1000) / 1000)

        let Tmax = Math.max(Math.min(...table.map(o => o.tx)), ...table.map(o => o.tx)) * Math.pow(10, -3)
        console.log('T max: ', Math.round(Tmax * 1000) / 1000)

        return {fleche_max: fleche_max, mydmax: MydMax, tmax: Tmax}
    }

    function calculMatriceZ_Trav(q1, q2, q3){
        let constante_1 = (q1 * (Math.pow(L_trav.value, 4) / 24));
        let constante_1_2 = constante_1 - (q1 - q2) * (Math.pow((L_trav.value - d1_trav.value), 4) / 24)
        let constante_1_3 = constante_1_2 - (q2 - q3) * (Math.pow((L_trav.value - d2_trav.value), 4) / 24)
        let constante_1_4 = constante_1_3

        let constante_2 = q1 * d1_trav.value 
        let constante_2_2 = constante_2 + q2 * (d2_trav.value - d1_trav.value)
        let constante_2_3 = constante_2_2 + q3 * (L_trav.value - d2_trav.value)
        let constante_2_4 = constante_2_3

        let constante_3 = q1 * Math.pow(d1_trav.value, 2) / 2
        let constante_3_2 = constante_3 + q2 * (d2_trav.value - d1_trav.value) * (d1_trav.value + (d2_trav.value - d1_trav.value)/2)
        let constante_3_3 = constante_3_2 + q3 * (longueur_traverse.value - d2_trav.value) * (d2_trav.value + (L_trav.value - d2_trav.value)/2) 
        // console.table('Constante', Math.round(constante_1_4), Math.round(constante_2_4 * 1000) / 1000, Math.round(constante_3_3 * 10000) / 10000)

        let matrice_1 = [
            [L_trav.value, Math.pow(L_trav.value, 3) / 6, 0],
            [0, 1, 1],
            [0, 0, L_trav.value]
        ]
        let resultMatrice1 = determinant(matrice_1)
        console.log('Determinant Matrice 1: ', Math.round(resultMatrice1))

        let matrice_2 = [
            [constante_1_4, Math.pow(L_trav.value, 3) / 6, 0],
            [constante_2_4, 1, 1],
            [constante_3_3, 0, L_trav.value]
        ]
        let resultMatrice2 = determinant(matrice_2)
        console.log('Determinant Matrice 2: ', Math.round(resultMatrice2))

        let matrice_3 = [
            [L_trav.value, constante_1_4, 0],
            [0, constante_2_4, 1],
            [0, constante_3_3, L_trav.value],
        ]
        let resultMatrice3 = determinant(matrice_3)
        console.log('Determinant Matrice 3: ', Math.round(resultMatrice3))

        let matrice_4 = [
            [L_trav.value, Math.pow(L_trav.value, 3) / 6, constante_1_4],
            [0, 1, constante_2_4],
            [0, 0 , constante_3_3]
        ]

        let resultMatrice4 = determinant(matrice_4)
        console.log('Determinant Matrice 4: ', Math.round(resultMatrice4))

        let Ely0 = resultMatrice2 / resultMatrice1
        let Ya = resultMatrice3 / resultMatrice1
        let Yb = resultMatrice4 / resultMatrice1
        console.log('El y\'(0): ', Math.round(Ely0), 'Ya: ', Math.round(Ya * 100000) / 100000, 'Yb: ', Math.round(Yb * 100000) / 100000)

        let table = []
        let nice_table = []
        // {x: 0,  ely: 0,yx: 0,mydx: 0,tx: 0,forcex: 0}
        table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Ya - q1 * 0,
            forcex: 0
        })

        nice_table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Math.round((Ya - q1 * 0) * 10000000) / 10000000,
            forcex: 0
        })

        let i = 0
        let x = 0
        while(i < 150){
            i++
            let X
            let elyR
            let yxR
            let mydxR
            let txR
            let forcexR

            if(i > 100 && i < 151){
                X = x + a3_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_trav.value), 4) / 24 - q2 * Math.pow((X - d1_trav.value), 4) / 24 + q2 * Math.pow((X - d2_trav.value), 4) / 24 - q3 * Math.pow((X - d2_trav.value), 4) / 24; 
                yxR = elyR / (module_flexion.value * info.value.hauteur_section_chevetre * Math.pow(info.value.largeur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_trav.value), 2) / 2 - q2 * Math.pow((X - d1_trav.value), 2) / 2 + q2 * Math.pow((X - d2_trav.value), 2) / 2 - q3 * Math.pow((X - d2_trav.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_trav.value) - q2 * (X - d1_trav.value) + q2 * (X - d2_trav.value) - q3 * (X - d2_trav.value)
                forcexR = q3
            } else if(i > 50 && i < 101){
                X = x + a2_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_trav.value), 4) / 24 - q2 * Math.pow((X - d1_trav.value), 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.hauteur_section_chevetre * Math.pow(info.value.largeur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_trav.value), 2) / 2 - q2 * Math.pow((X - d1_trav.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_trav.value) - q2 * (X - d1_trav.value)
                forcexR = q2
            } else {
                X = x + a1_trav.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.hauteur_section_chevetre * Math.pow(info.value.largeur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2
                txR = Ya - q1 * X
                forcexR = q1
            }
            x = X;

            table.push({
                x: X, 
                ely: elyR,
                yx: yxR,
                mydx: mydxR,
                tx: txR,
                forcex: forcexR,
            })

            nice_table.push({
                x: Math.round(X * 10) / 10, 
                ely: Math.round(elyR * 10) / 10,
                yx: Math.round(yxR * 1000000) / 1000000,
                mydx: Math.round(mydxR * 10000) / 10000,
                tx: Math.round(txR * 10000000) / 10000000,
                forcex: Math.round(forcexR * 1000) / 1000,
            })
        }   
        // console.table(nice_table)

        let fleche_max = Math.max(Math.abs(Math.min(...table.map(o => o.yx))) ,...table.map(o => o.yx))
        console.log('Flèche Max: ', Math.round(fleche_max * 100) / 100)

        let MydMax = Math.max(...table.map(o => o.mydx)) * Math.pow(10, -6)
        console.log('Myd max: ', Math.round(MydMax * 1000) / 1000)

        let Tmax = Math.max(Math.min(...table.map(o => o.tx)), ...table.map(o => o.tx)) * Math.pow(10, -3)
        console.log('T max: ', Math.round(Tmax * 1000) / 1000)

        return {fleche_max: fleche_max, mydmax: MydMax, tmax: Tmax}
    }

    function calculMatriceY_Mont(q1, q2, q3){
        let constante_1 = (q1 * (Math.pow(L_mont.value, 4) / 24));
        let constante_1_2 = constante_1 - (q1 - q2) * (Math.pow((L_mont.value - d1_mont.value), 4) / 24)
        let constante_1_3 = constante_1_2 - (q2 - q3) * (Math.pow((L_mont.value - d2_mont.value), 4) / 24)
        let constante_1_4 = constante_1_3

        let constante_2 = q1 * d1_mont.value 
        let constante_2_2 = constante_2 + q2 * (d2_mont.value - d1_mont.value)
        let constante_2_3 = constante_2_2 + q3 * (L_mont.value - d2_mont.value)
        let constante_2_4 = constante_2_3

        let constante_3 = q1 * Math.pow(d1_mont.value, 2) / 2
        let constante_3_2 = constante_3 + q2 * (d2_mont.value - d1_mont.value) * (d1_mont.value + (d2_mont.value - d1_mont.value)/2)
        let constante_3_3 = constante_3_2 + q3 * (longueur_montant.value - d2_mont.value) * (d2_mont.value + (L_mont.value - d2_mont.value)/2) 
        // console.table('Constante', Math.round(constante_1_4), Math.round(constante_2_4 * 1000) / 1000, Math.round(constante_3_3 * 10000) / 10000)

        let matrice_1 = [
            [L_mont.value, Math.pow(L_mont.value, 3) / 6, 0],
            [0, 1, 1],
            [0, 0, L_mont.value]
        ]
        let resultMatrice1 = determinant(matrice_1)
        console.log('Determinant Matrice 1: ', Math.round(resultMatrice1))

        let matrice_2 = [
            [constante_1_4, Math.pow(L_mont.value, 3) / 6, 0],
            [constante_2_4, 1, 1],
            [constante_3_3, 0, L_mont.value]
        ]
        let resultMatrice2 = determinant(matrice_2)
        console.log('Determinant Matrice 2: ', Math.round(resultMatrice2))

        let matrice_3 = [
            [L_mont.value, constante_1_4, 0],
            [0, constante_2_4, 1],
            [0, constante_3_3, L_mont.value],
        ]
        let resultMatrice3 = determinant(matrice_3)
        console.log('Determinant Matrice 3: ', Math.round(resultMatrice3))

        let matrice_4 = [
            [L_mont.value, Math.pow(L_mont.value, 3) / 6, constante_1_4],
            [0, 1, constante_2_4],
            [0, 0 , constante_3_3]
        ]

        let resultMatrice4 = determinant(matrice_4)
        console.log('Determinant Matrice 4: ', Math.round(resultMatrice4))

        let Ely0 = resultMatrice2 / resultMatrice1
        let Ya = resultMatrice3 / resultMatrice1
        let Yb = resultMatrice4 / resultMatrice1
        console.log('El y\'(0): ', Math.round(Ely0), 'Ya: ', Math.round(Ya * 100000) / 100000, 'Yb: ', Math.round(Yb * 100000) / 100000)

        let table = []
        let nice_table = []
        // {x: 0,  ely: 0,yx: 0,mydx: 0,tx: 0,forcex: 0}
        table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Ya - q1 * 0,
            forcex: 0
        })

        nice_table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Math.round((Ya - q1 * 0) * 10000000) / 10000000,
            forcex: 0
        })

        let i = 0
        let x = 0
        while(i < 150){
            i++
            let X
            let elyR
            let yxR
            let mydxR
            let txR
            let forcexR

            if(i > 100 && i < 151){
                X = x + a3_mont.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_mont.value), 4) / 24 - q2 * Math.pow((X - d1_mont.value), 4) / 24 + q2 * Math.pow((X - d2_mont.value), 4) / 24 - q3 * Math.pow((X - d2_mont.value), 4) / 24; 
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_mont.value), 2) / 2 - q2 * Math.pow((X - d1_mont.value), 2) / 2 + q2 * Math.pow((X - d2_mont.value), 2) / 2 - q3 * Math.pow((X - d2_mont.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_mont.value) - q2 * (X - d1_mont.value) + q2 * (X - d2_mont.value) - q3 * (X - d2_mont.value)
                forcexR = q3
            } else if(i > 50 && i < 101){
                X = x + a2_mont.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_mont.value), 4) / 24 - q2 * Math.pow((X - d1_mont.value), 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_mont.value), 2) / 2 - q2 * Math.pow((X - d1_mont.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_mont.value) - q2 * (X - d1_mont.value)
                forcexR = q2
            } else {
                X = x + a1_mont.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_section_chevetre * Math.pow(info.value.hauteur_section_chevetre, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2
                txR = Ya - q1 * X
                forcexR = q1
            }
            x = X;

            table.push({
                x: X, 
                ely: elyR,
                yx: yxR,
                mydx: mydxR,
                tx: txR,
                forcex: forcexR,
            })

            nice_table.push({
                x: Math.round(X * 10) / 10, 
                ely: Math.round(elyR * 10) / 10,
                yx: Math.round(yxR * 1000000) / 1000000,
                mydx: Math.round(mydxR * 10000) / 10000,
                tx: Math.round(txR * 10000000) / 10000000,
                forcex: Math.round(forcexR * 1000) / 1000,
            })
        }   
        // console.table(nice_table)

        let fleche_max = Math.max(Math.abs(Math.min(...table.map(o => o.yx))) ,...table.map(o => o.yx))
        console.log('Flèche Max: ', Math.round(fleche_max * 100) / 100)

        let MydMax = Math.max(...table.map(o => o.mydx)) * Math.pow(10, -6)
        console.log('Myd max: ', Math.round(MydMax * 1000) / 1000)

        let Tmax = Math.max(Math.min(...table.map(o => o.tx)), ...table.map(o => o.tx)) * Math.pow(10, -3)
        console.log('T max: ', Math.round(Tmax * 1000) / 1000)

        return {fleche_max: fleche_max, mydmax: MydMax, tmax: Tmax}
    }

    function calculMatriceY_Mont2(q1, q2, q3){
        let constante_1 = (q1 * (Math.pow(L_mont2.value, 4) / 24));
        let constante_1_2 = constante_1 - (q1 - q2) * (Math.pow((L_mont2.value - d1_mont2.value), 4) / 24)
        let constante_1_3 = constante_1_2 - (q2 - q3) * (Math.pow((L_mont2.value - d2_mont2.value), 4) / 24)
        let constante_1_4 = constante_1_3

        let constante_2 = q1 * d1_mont2.value 
        let constante_2_2 = constante_2 + q2 * (d2_mont2.value - d1_mont2.value)
        let constante_2_3 = constante_2_2 + q3 * (L_mont2.value - d2_mont2.value)
        let constante_2_4 = constante_2_3

        let constante_3 = q1 * Math.pow(d1_mont2.value, 2) / 2
        let constante_3_2 = constante_3 + q2 * (d2_mont2.value - d1_mont2.value) * (d1_mont2.value + (d2_mont2.value - d1_mont2.value)/2)
        let constante_3_3 = constante_3_2 + q3 * (longueur_montant2.value - d2_mont2.value) * (d2_mont2.value + (L_mont2.value - d2_mont2.value)/2) 
        // console.table('Constante', Math.round(constante_1_4), Math.round(constante_2_4 * 1000) / 1000, Math.round(constante_3_3 * 10000) / 10000)

        let matrice_1 = [
            [L_mont2.value, Math.pow(L_mont2.value, 3) / 6, 0],
            [0, 1, 1],
            [0, 0, L_mont2.value]
        ]
        let resultMatrice1 = determinant(matrice_1)
        console.log('Determinant Matrice 1: ', Math.round(resultMatrice1))

        let matrice_2 = [
            [constante_1_4, Math.pow(L_mont2.value, 3) / 6, 0],
            [constante_2_4, 1, 1],
            [constante_3_3, 0, L_mont2.value]
        ]
        let resultMatrice2 = determinant(matrice_2)
        console.log('Determinant Matrice 2: ', Math.round(resultMatrice2))

        let matrice_3 = [
            [L_mont2.value, constante_1_4, 0],
            [0, constante_2_4, 1],
            [0, constante_3_3, L_mont2.value],
        ]
        let resultMatrice3 = determinant(matrice_3)
        console.log('Determinant Matrice 3: ', Math.round(resultMatrice3))

        let matrice_4 = [
            [L_mont2.value, Math.pow(L_mont2.value, 3) / 6, constante_1_4],
            [0, 1, constante_2_4],
            [0, 0 , constante_3_3]
        ]

        let resultMatrice4 = determinant(matrice_4)
        console.log('Determinant Matrice 4: ', Math.round(resultMatrice4))

        let Ely0 = resultMatrice2 / resultMatrice1
        let Ya = resultMatrice3 / resultMatrice1
        let Yb = resultMatrice4 / resultMatrice1
        console.log('El y\'(0): ', Math.round(Ely0), 'Ya: ', Math.round(Ya * 100000) / 100000, 'Yb: ', Math.round(Yb * 100000) / 100000)

        let table = []
        let nice_table = []
        // {x: 0,  ely: 0,yx: 0,mydx: 0,tx: 0,forcex: 0}
        table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Ya - q1 * 0,
            forcex: 0
        })

        nice_table.push({
            x: 0,  
            ely: 0,
            yx: 0,
            mydx: 0,
            tx: Math.round((Ya - q1 * 0) * 10000000) / 10000000,
            forcex: 0
        })

        let i = 0
        let x = 0
        while(i < 150){
            i++
            let X
            let elyR
            let yxR
            let mydxR
            let txR
            let forcexR

            if(i > 100 && i < 151){
                X = x + a3_mont2.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_mont2.value), 4) / 24 - q2 * Math.pow((X - d1_mont2.value), 4) / 24 + q2 * Math.pow((X - d2_mont2.value), 4) / 24 - q3 * Math.pow((X - d2_mont2.value), 4) / 24; 
                yxR = elyR / (module_flexion.value * info.value.largeur_chevron * Math.pow(info.value.hauteur_chevron, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_mont2.value), 2) / 2 - q2 * Math.pow((X - d1_mont2.value), 2) / 2 + q2 * Math.pow((X - d2_mont2.value), 2) / 2 - q3 * Math.pow((X - d2_mont2.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_mont2.value) - q2 * (X - d1_mont2.value) + q2 * (X - d2_mont2.value) - q3 * (X - d2_mont2.value)
                forcexR = q3
            } else if(i > 50 && i < 101){
                X = x + a2_mont2.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24 + q1 * Math.pow((X - d1_mont2.value), 4) / 24 - q2 * Math.pow((X - d1_mont2.value), 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_chevron * Math.pow(info.value.hauteur_chevron, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2 + q1 * Math.pow((X - d1_mont2.value), 2) / 2 - q2 * Math.pow((X - d1_mont2.value), 2) / 2
                txR = Ya - q1 * X + q1 * (X - d1_mont2.value) - q2 * (X - d1_mont2.value)
                forcexR = q2
            } else {
                X = x + a1_mont2.value / 50
                elyR = Ely0 * X + Ya * Math.pow(X, 3) / 6 - q1 * Math.pow(X, 4) / 24;
                yxR = elyR / (module_flexion.value * info.value.largeur_chevron * Math.pow(info.value.hauteur_chevron, 3) /12);
                mydxR = Ya * X - q1 * Math.pow(X, 2) / 2
                txR = Ya - q1 * X
                forcexR = q1
            }
            x = X;

            table.push({
                x: X, 
                ely: elyR,
                yx: yxR,
                mydx: mydxR,
                tx: txR,
                forcex: forcexR,
            })

            nice_table.push({
                x: Math.round(X * 10) / 10, 
                ely: Math.round(elyR * 10) / 10,
                yx: Math.round(yxR * 1000000) / 1000000,
                mydx: Math.round(mydxR * 10000) / 10000,
                tx: Math.round(txR * 10000000) / 10000000,
                forcex: Math.round(forcexR * 1000) / 1000,
            })
        }   
        console.table(nice_table)

        let fleche_max = Math.max(Math.abs(Math.min(...table.map(o => o.yx))) ,...table.map(o => o.yx))
        console.log('Flèche Max: ', Math.round(fleche_max * 100) / 100)

        let MydMax = Math.max(...table.map(o => o.mydx)) * Math.pow(10, -6)
        console.log('Myd max: ', Math.round(MydMax * 1000) / 1000)

        let Tmax = Math.max(Math.min(...table.map(o => o.tx)), ...table.map(o => o.tx)) * Math.pow(10, -3)
        console.log('T max: ', Math.round(Tmax * 1000) / 1000)

        return {fleche_max: fleche_max, mydmax: MydMax, tmax: Tmax}
    }

    function reset(){
        return new Promise((resolve, reject) => {
            currentLargeur.value = 0;
            best.value = {};
            arr_optimisation.value = [];
            largeur_opti.value = 0;
            initial.value = [];
            init.value = false;
            loading.value = false;
            last_refID.value = 0;
            messageShow.value = false;
            messageToast.value = '';
            formStep.value = 1;
            info.value = {};
            resolve();
        })
    }

    function resetCalculValeurs(){
        return new Promise((resolve, reject) => {
            Sny.value = ref(0)
            Snz.value = ref(0)
            best.value = ref();
            
            QpZ.value = ref(0);
            FwN_result.value = ref(0);
            longueur_traverse.value = ref(0)
            longueur_montant.value = ref(0)
            longueur_montant2.value = ref(0)
            module_flexion.value = ref(0)

            PPtotVertical.value = ref(0)
            PPtotY.value = ref(0)
            PPtotZ.value = ref(0)

            d1_trav.value = ref(0)
            d2_trav.value = ref(0)
            L_trav.value = ref(0)

            a1_trav.value = ref(0)
            a2_trav.value = ref(0) 
            a3_trav.value = ref(0)

            d1_mont.value = ref(0)
            d2_mont.value = ref(0)
            L_mont.value = ref(0)

            a1_mont.value = ref(0)
            a2_mont.value = ref(0) 
            a3_mont.value = ref(0)

            d1_mont2.value = ref(0)
            d2_mont2.value = ref(0)
            L_mont2.value = ref(0)

            a1_mont2.value = ref(0)
            a2_mont2.value = ref(0) 
            a3_mont2.value = ref(0)

            fleche_permanente_y.value = ref(0)
            fleche_neige_y.value = ref(0)
            fleche_pression_y.value = ref(0)

            mydmax_permanente_y.value = ref(0)
            mydmax_neige_y.value = ref(0)
            mydmax_pression_y.value = ref(0)

            tmax_permanente_y.value = ref(0)
            tmax_neige_y.value = ref(0)
            tmax_pression_y.value = ref(0)

            fleche_permanente_z.value = ref(0)
            fleche_neige_z.value = ref(0)
            fleche_pression_z.value = ref(0)

            mydmax_permanente_z.value = ref(0)
            mydmax_neige_z.value = ref(0)
            mydmax_pression_z.value = ref(0)

            tmax_permanente_z.value = ref(0)
            tmax_neige_z.value = ref(0)
            tmax_pression_z.value = ref(0)

            fleche_permanente_y_mont.value = ref(0)
            fleche_neige_y_mont.value = ref(0)
            fleche_pression_y_mont.value = ref(0)

            mydmax_permanente_y_mont.value = ref(0)
            mydmax_neige_y_mont.value = ref(0)
            mydmax_pression_y_mont.value = ref(0)

            tmax_permanente_y_mont.value = ref(0)
            tmax_neige_y_mont.value = ref(0)
            tmax_pression_y_mont.value = ref(0)

            fleche_permanente_y_mont2.value = ref(0)
            fleche_neige_y_mont2.value = ref(0)
            fleche_pression_y_mont2.value = ref(0)

            mydmax_permanente_y_mont2.value = ref(0)
            mydmax_neige_y_mont2.value = ref(0)
            mydmax_pression_y_mont2.value = ref(0)        
            tmax_permanente_y_mont2.value = ref(0)
            tmax_neige_y_mont2.value = ref(0)
            tmax_pression_y_mont2.value = ref(0)

            n_defavorable.value = ref(0)
            traverse_deformation.value = ref(0)
            montant_flexion.value = ref(0)
            montant_cisaillement.value = ref(0)
            montant_deformation.value = ref(0)
            chevron_ext_flexion.value = ref(0)
            chevron_ext_flexion_cisaillement.value = ref(0)
            chevron_ext_deformation.value = ref(0)

            setTimeout(() => {
                resolve();  
            }, 500)
        })
    }

    return {
        info,
        best,
        initial,
        init_largeur_total_chevetre,
        init_hauteur_total_chevetre,
        init_largeur_section_chevetre,
        longueur_traverse,
        loading,
        formStep,
        formStep_valid,
        kmod,
        largeur_opti,
        currentLargeur,
        init,
        PPtotVertical,
        Sn_neige,
        FwN_result,
        Fw_result,
        messageShow,
        messageToast,
        getKMOD,
        setInput,
        sendInput,
        createCalcul,
        uploadDoc,
        reset
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFormStore, import.meta.hot));
}
